import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  loggedInUserVar,
  clearLoginVars,
  settingsVar,
} from '../../../libs/apollo'
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar'
import {
  Unlock,
  Lock,
  Buildings,
  BoxArrowInLeft,
  PersonBoundingBox,
} from 'react-bootstrap-icons'
import './Navigation.css'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { useIntercom } from 'react-use-intercom'

const OrganizationContactNavigation = ({ setNavigationOpen }) => {
  let isCollapsed = false
  let locked = true
  let navState = localStorage.getItem('navState')
  if (navState) {
    navState = JSON.parse(navState)
    isCollapsed = navState.collapsed
    locked = navState.locked
  }
  const settings = useReactiveVar(settingsVar)
  const location = useLocation()
  const [navLocked, setNavLocked] = useState(locked)
  const { shutdown } = useIntercom()
  const { collapsed, collapseSidebar } = useProSidebar()
  const history = useHistory()
  let loggedInUser = useReactiveVar(loggedInUserVar)

  const toggleSidebar = (open) => {
    if (!navLocked) {
      localStorage.setItem(
        'navState',
        JSON.stringify({ collapsed: open, locked: navLocked })
      )
      collapseSidebar(open)
    }
  }

  useEffect(() => {
    setNavigationOpen(!collapsed)
  }, [setNavigationOpen, collapsed])

  const subject = loggedInUser.groups.edges
    .map((group) => group.node.name)
    .includes('Subject')
  let singleOrg = false
  if (loggedInUser.organizationContacts.edges.length === 1) {
    singleOrg = loggedInUser.organizationContacts.edges[0]
  }

  useEffect(() => {
    if (!collapsed) {
      setNavigationOpen(true)
    } else {
      setNavigationOpen(false)
    }
  }, [setNavigationOpen, collapsed])

  const handleLogout = () => {
    clearLoginVars()
    localStorage.clear()
    if (settings.intercomAppId) {
      shutdown()
    }
    history.push('/')
  }

  if (!settings) return <></>
  return (
    <>
      <div
        style={{ backgroundColor: settings.colorScheme, flex: 1 }}
        className="sidebar-container"
      >
        <Sidebar defaultCollapsed={isCollapsed} width="136px">
          <Menu>
            {!singleOrg && (
              <>
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() => history.push('/organizations/contact')}
                  className={
                    location.pathname === '/organizations/contact'
                      ? 'menu-item-active'
                      : ''
                  }
                  active={location.pathname === '/organizations/contact'}
                >
                  {collapsed ? (
                    <Buildings />
                  ) : (
                    <>
                      <Buildings className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Organizations</span>
                    </>
                  )}
                </MenuItem>
              </>
            )}
            {singleOrg && (
              <>
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() =>
                    history.push(`/organization/${singleOrg.node.id}/contact`)
                  }
                  active={
                    location.pathname ===
                    `/organization/${singleOrg.node.id}/contact`
                  }
                >
                  {collapsed ? (
                    <Buildings />
                  ) : (
                    <>
                      <Buildings className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Home</span>
                    </>
                  )}
                </MenuItem>
              </>
            )}

            {subject && (
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                onClick={() =>
                  history.push(`/subject/${loggedInUser?.subject?.id}/contact`)
                }
                active={
                  location.pathname ===
                  `/subject/${loggedInUser?.subject?.id}/contact`
                }
              >
                {collapsed ? (
                  <PersonBoundingBox />
                ) : (
                  <>
                    <PersonBoundingBox className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Profile</span>
                  </>
                )}
              </MenuItem>
            )}
            <MenuItem
              onClick={handleLogout}
              className="title-space"
              onMouseEnter={() => {
                toggleSidebar(false)
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {collapsed ? (
                <BoxArrowInLeft />
              ) : (
                <>
                  <BoxArrowInLeft className="mr-2" />
                  <span style={{ fontSize: '11.5px' }}>Logout</span>
                </>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  'navState',
                  JSON.stringify({ collapsed, locked: !navLocked })
                )
                setNavLocked(!navLocked)
              }}
              onMouseEnter={() => {
                if (!collapsed) {
                  toggleSidebar(false)
                }
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {navLocked && <Lock style={{ fontSize: '14px' }} />}
              {!navLocked && <Unlock style={{ fontSize: '14px' }} />}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  )
}

export default OrganizationContactNavigation
