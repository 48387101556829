import React, { useState } from 'react'
import { useQuery, useMutation, gql, useReactiveVar } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Loading from '../common/Loading'
import { Button, Form } from 'react-bootstrap'
import { PlusCircle, XCircle, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import { loggedInUserVar } from '../../libs/apollo'
import moment from 'moment'
import './Comments.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import DeleteCommentModal from './DeleteCommentModal'

const COMMENTS_QUERY = gql`
  query CommentsQuery($taskId: ID!) {
    comments(task: $taskId, first: 250) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          created
          updated
          comment
          gaiaUser {
            fullName
            id
          }
        }
      }
    }
  }
`

const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      comment {
        id
        created
        updated
        comment
        gaiaUser {
          fullName
          id
        }
      }
    }
  }
`

const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      comment {
        id
        updated
      }
    }
  }
`

const Comments = ({ taskId }) => {
  const { loading, data, refetch, fetchMore } = useQuery(COMMENTS_QUERY, {
    variables: { taskId },
    fetchPolicy: 'network-only',
  })

  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [editingCommentId, setEditingCommentId] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState(null)

  const [showInput, setShowInput] = useState(false)

  const toggleDeleteModal = (commentId) => {
    setCommentToDelete(commentId)
    setShowDeleteModal(!showDeleteModal)
  }

  const [createComment] = useMutation(CREATE_COMMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Comment Saved')
      refetch()
    },
    errorPolicy: 'all',
    refetchQueries: ['CommentsQuery'],
  })

  const [updateComment] = useMutation(UPDATE_COMMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Comment Saved')
      setEditingCommentId(null)
      refetch()
    },
    errorPolicy: 'all',
    refetchQueries: ['CommentsQuery'],
  })

  const editFormik = useFormik({
    initialValues: { comment: '' },
    validationSchema: Yup.object({
      comment: Yup.string().required('required'),
    }),
    onSubmit: async (values) => {
      await updateComment({
        variables: {
          input: {
            commentInput: {
              id: editingCommentId,
              comment: values.comment,
            },
          },
        },
      })
      setEditingCommentId(null)
    },
  })

  const formik = useFormik({
    initialValues: { comment: '' },
    validationSchema: Yup.object({
      comment: Yup.string().required('required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      event.preventDefault()

      await createComment({
        variables: {
          input: {
            commentInput: {
              taskId: taskId,
              comment: values.comment,
            },
          },
        },
      })

      resetForm()
      setShowInput(false)
    },
  })

  const handleFetchMoreComments = () => {
    if (!data?.comments?.pageInfo?.hasNextPage) return
    fetchMore({
      variables: {
        taskId,
        cursor: data.comments.pageInfo.endCursor,
      },
    })
  }
  if (loading || !data?.comments)
    return (
      <div className="mt-5 mb-5">
        <Loading />
      </div>
    )
  return (
    <>
      <div className="task-comments-container">
        <InfiniteScroll
          dataLength={data?.comments?.edges?.length || 0}
          next={handleFetchMoreComments}
          hasMore={data?.comments?.pageInfo?.hasNextPage}
          loader={<Loading />}
        >
          <div
            style={
              data.comments.edges.length > 5
                ? {
                    maxHeight: '500px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                  }
                : null
            }
          >
            {data.comments.edges.map(({ node }) => (
              <div key={node.id} className="comment">
                <div className="comment-header">
                  <strong>{node.gaiaUser.fullName}</strong>{' '}
                  <span className="timestamp">
                    {moment(node.created).format('MM/DD/YYYY hh:mmA')}
                    {loggedInUser?.id === node.gaiaUser.id &&
                      (!editingCommentId || editingCommentId !== node.id) && (
                        <span
                          className="ml-1"
                          style={{ position: 'relative', top: '-2px' }}
                        >
                          <Button
                            variant="link"
                            onClick={() => {
                              setEditingCommentId(node.id)
                              editFormik.setValues({ comment: node.comment })
                            }}
                          >
                            <PlusCircle size={13} className="mr-1" />
                            <span style={{ fontSize: '12px' }}>Edit</span>
                          </Button>
                          <Button
                            variant="link"
                            onClick={() => toggleDeleteModal(node.id)}
                          >
                            <Trash size={13} />{' '}
                            <span style={{ fontSize: '12px' }}>Delete</span>
                          </Button>
                        </span>
                      )}
                  </span>
                </div>
                <div className="comment-body">
                  {editingCommentId === node.id ? (
                    <Form className="edit-comment-form">
                      <Form.Control
                        as="textarea"
                        name="comment"
                        value={editFormik.values.comment}
                        onChange={editFormik.handleChange}
                        onBlur={editFormik.handleBlur}
                        isInvalid={
                          editFormik.touched.comment &&
                          !!editFormik.errors.comment
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {editFormik.errors.comment}
                      </Form.Control.Feedback>
                      <div className="comment-actions">
                        <Button
                          onClick={editFormik.handleSubmit}
                          disabled={editFormik.isSubmitting}
                          variant="link"
                        >
                          <PlusCircle size={13} className="mr-1" />
                          Save
                        </Button>
                        <Button
                          variant="link"
                          onClick={() => setEditingCommentId(null)}
                        >
                          <XCircle size={13} className="mr-1" />
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  ) : (
                    <Form.Control
                      as="textarea"
                      value={node.comment}
                      readOnly
                      style={{
                        fontSize: '0.875rem', // Makes font smaller (14px)
                        backgroundColor: 'white', // Removes grey background
                        opacity: '1', // Ensures text isn't faded due to readOnly
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
        {!showInput ? (
          <Button
            variant="link"
            onClick={() => setShowInput(true)}
            className="add-comment-btn"
          >
            <PlusCircle size={18} className="mr-2" /> New Comment
          </Button>
        ) : (
          <Form className="comment-form">
            <Form.Group>
              <Form.Control
                as="textarea"
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.comment && !!formik.errors.comment}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comment}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="comment-actions">
              <Button
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
                variant="link"
              >
                <PlusCircle size={18} className="mr-2" />
                Save
              </Button>
              <Button variant="link" onClick={() => setShowInput(false)}>
                <XCircle size={18} className="mr-2" />
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </div>
      <DeleteCommentModal
        showModal={showDeleteModal}
        toggleModal={() => setShowDeleteModal(false)}
        commentId={commentToDelete}
        refetch={refetch}
      />
    </>
  )
}

export default Comments
