import React, { useState, useEffect } from 'react'
import { Trash, ExclamationCircle } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ErrorMessage, FieldArray } from 'formik'
import { Form, Col, Table } from 'react-bootstrap'
import { gql, useLazyQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import toast from 'react-hot-toast'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import 'react-datepicker/dist/react-datepicker.css'
import Loading from '../../common/Loading'

export default function Bookable(props) {
  const { formik } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const [categoryValue, setCategoryValue] = useState('')
  const [packageCategories, setPackageCategories] = useState(null)
  const [subjectGroupValue, setSubjectGroupValue] = useState('')
  const [displaySubjectGroupResults, setDisplaySubjectGroupResults] =
    useState(false)

  const startOfToday = DateTime.now()

  const GET_CATEGORIES = gql`
    query PackageCategories($first: Int) {
      packageCategories(first: $first) {
        edges {
          cursor
          node {
            id
            name
            sessionPackages(customPriceAndDuration: false) {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  `

  const GET_SUBJECT_GROUPS = gql`
    query SubjectGroups($after: String, $first: Int, $nameIcontains: String) {
      subjectGroups(
        after: $after
        first: $first
        name_Icontains: $nameIcontains
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            organization {
              id
              name
              contacts {
                edges {
                  node {
                    id
                    fullName
                    email
                    phoneNumber
                  }
                }
              }
            }
            endDateTime
            startDateTime
            name
            sessionPackages {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  `

  const [searchCategories, { data: categoryData }] = useLazyQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setPackageCategories(data.packageCategories.edges)
      },
    }
  )

  const [
    searchSubjectGroups,
    { data: subjectGroupData, fetchMore: fetchMoreSubjectGroups },
  ] = useLazyQuery(GET_SUBJECT_GROUPS, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (!packageCategories) {
      searchCategories({
        variables: {
          first: 250,
        },
      })
    }
  }, [])

  const handleSubjectGroupChange = (e) => {
    setSubjectGroupValue(e.target.value)
    if (e.target.value != '') {
      searchSubjectGroups({
        variables: {
          first: 10,
          nameIcontains: e.target.value,
        },
      })
      setDisplaySubjectGroupResults(true)
    } else {
      setDisplaySubjectGroupResults(false)
    }
  }

  const handleFetchMoreSubjectGroups = () => {
    fetchMoreSubjectGroups({
      variables: {
        after: subjectGroupData.subjectGroups.pageInfo.endCursor,
        first: 10,
        endDateTimeGt: startOfToday,
        nameIcontains: subjectGroupValue,
      },
    })
  }

  const handleSubjectGroupClick = (subjectGroupNode, arrayHelpers) => {
    const sessionPackages = subjectGroupNode.sessionPackages.edges.map(
      (seshPackage) => ({
        id: seshPackage.node.id,
        title: seshPackage.node.title,
      })
    )
    const orgContacts = subjectGroupNode.organization.contacts.edges.map(
      (contact) => ({
        id: contact.node.id,
        fullName: contact.node.fullName,
        email: contact.node.email,
        phoneNumber: contact.node.phoneNumber ? contact.node.phoneNumber : '',
      })
    )
    let findIndex = formik.values.subjectGroups.findIndex(
      (x) => x.id === subjectGroupNode.id
    )
    if (findIndex === -1) {
      arrayHelpers.push({
        node: subjectGroupNode,
        name: subjectGroupNode.name,
        id: subjectGroupNode.id,
        packages: sessionPackages,
        orgContacts,
      })
    } else {
      toast.error('Can not add duplicate Subject Group')
    }
  }

  const handleSubjectGroupBlur = () => {
    setDisplaySubjectGroupResults(false)
    formik.setFieldTouched('subjectGroups', true)
    setSubjectGroupValue('')
  }

  const handlePackageCategoryChange = (e, arrayHelpers) => {
    const categoryNode = packageCategories.find(
      (cat) => cat.node.id === e.target.value
    )?.node
    if (categoryNode) {
      handleCategoryClick(categoryNode, arrayHelpers)
      setCategoryValue('') // Only clear after successful add
    } else {
      setCategoryValue(e.target.value)
    }
  }

  const handleCategoryClick = (categoryNode, arrayHelpers) => {
    const packageIdTitle =
      categoryNode.sessionPackages.edges.length > 0
        ? categoryNode.sessionPackages.edges.map((p) => {
            return {
              id: p.node.id,
              title: p.node.title,
            }
          })
        : []
    let findIndex = formik.values.packageCategories.findIndex(
      (cat) => cat.id === categoryNode.id
    )
    if (findIndex === -1) {
      arrayHelpers.push({
        name: categoryNode.name,
        id: categoryNode.id,
        packages: packageIdTitle,
      })
    } else {
      toast.error('Can not add duplicate package categories')
    }
  }

  const handlePackageRemoveClick = (
    isSubjectGroup,
    packageIndex,
    subjectGroupIndex,
    packageArrayHelpers,
    groupCategoryHelpers,
    formik
  ) => {
    if (isSubjectGroup) {
      if (
        formik.values.subjectGroups[subjectGroupIndex].packages.length === 1
      ) {
        groupCategoryHelpers.remove(subjectGroupIndex)
      } else {
        packageArrayHelpers.remove(packageIndex)
      }
    } else {
      if (
        formik.values.packageCategories[subjectGroupIndex].packages.length === 1
      ) {
        groupCategoryHelpers.remove(subjectGroupIndex)
      } else {
        packageArrayHelpers.remove(packageIndex)
      }
    }
  }

  return (
    <Form.Row>
      <Form.Group as={Col} md={4}>
        <FieldArray
          name="subjectGroups"
          render={(arrayHelpers) => (
            <>
              <Form.Label>Subject Groups</Form.Label>
              <div style={{ position: 'relative' }}>
                {canMutate && (
                  <Form.Control
                    placeholder="Search"
                    className="form-control-sm"
                    value={subjectGroupValue}
                    onBlur={handleSubjectGroupBlur}
                    onChange={(e) => handleSubjectGroupChange(e, formik)}
                  />
                )}
                {subjectGroupData && displaySubjectGroupResults ? (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 100,
                      width: '100%',
                      backgroundColor: 'white',
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }}
                  >
                    <InfiniteScroll
                      height={100}
                      dataLength={subjectGroupData.subjectGroups.edges.length}
                      next={handleFetchMoreSubjectGroups}
                      hasMore={
                        subjectGroupData.subjectGroups.pageInfo.hasNextPage
                      }
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {subjectGroupData.subjectGroups.edges.map(
                            (subjectGroup) => {
                              const { node } = subjectGroup
                              const existingSubGrp =
                                formik.values.subjectGroups.find(
                                  (sub) => sub.name === node.name
                                )
                              if (existingSubGrp) {
                                return
                              }
                              return (
                                <tr
                                  onMouseDown={() =>
                                    handleSubjectGroupClick(node, arrayHelpers)
                                  }
                                  key={node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small>{node.name}</small>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  </div>
                ) : null}
              </div>

              <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {formik.values.subjectGroups.map((subjectGroup, index) => {
                  return (
                    <div
                      key={index}
                      className="p-3 border border-dark rounded mt-2"
                    >
                      <p className="mb-0">{subjectGroup.name}</p>
                      {startOfToday >
                        DateTime.fromISO(subjectGroup.node.endDateTime) && (
                        <div className="text-danger">
                          <ExclamationCircle className="mr-2" />
                          <small>End date less than current date</small>
                        </div>
                      )}
                      {formik.values.subjectGroups[index].packages.length ===
                        0 && (
                        <div className="text-danger mb-1">
                          <ExclamationCircle className="mr-2" />
                          <small>Missing session packages</small>
                        </div>
                      )}
                      <FieldArray
                        name={`subjectGroups[${index}].packages`}
                        render={(packageArrayHelpers) => (
                          <>
                            {formik.values.subjectGroups[index].packages.map(
                              (sessionPackage, i) => {
                                return (
                                  <div
                                    key={sessionPackage.id}
                                    className="d-flex align-items-center mt-1"
                                    onClick={() => {
                                      if (canMutate) {
                                        handlePackageRemoveClick(
                                          true,
                                          i,
                                          index,
                                          packageArrayHelpers,
                                          arrayHelpers,
                                          formik
                                        )
                                      }
                                    }}
                                  >
                                    {canMutate && (
                                      <Trash className="mr-2 text-danger hover" />
                                    )}

                                    <small>{sessionPackage.title}</small>
                                  </div>
                                )
                              }
                            )}
                          </>
                        )}
                      />
                      {canMutate && (
                        <div
                          className={
                            formik.values.subjectGroups[index].packages.length >
                            0
                              ? 'mt-2 d-flex align-items-center hover text-danger'
                              : 'd-flex align-items-center hover text-danger'
                          }
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                        >
                          <Trash className="mr-2" />
                          <small>Remove</small>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </>
          )}
        />
        <ErrorMessage name="subjectGroups">
          {(msg) => <span className="text-danger mt-2 d-block">{msg}</span>}
        </ErrorMessage>
      </Form.Group>

      <Form.Group as={Col} md={4}>
        <FieldArray
          name="packageCategories"
          render={(arrayHelpers) => (
            <>
              <Form.Label>Package Categories</Form.Label>
              <div>
                {canMutate && (
                  <Form.Control
                    as="select"
                    className="form-control-sm"
                    value={categoryValue}
                    onChange={(e) =>
                      handlePackageCategoryChange(e, arrayHelpers)
                    }
                  >
                    <option value="">Select Package Category</option>
                    {packageCategories?.map((category) => {
                      const { node } = category
                      const existingCat = formik.values.packageCategories.find(
                        (sub) => sub.name === node.name
                      )
                      if (existingCat) return null
                      return (
                        <option key={node.id} value={node.id}>
                          {node.name} ({node.sessionPackages.edges.length}{' '}
                          packages)
                        </option>
                      )
                    })}
                  </Form.Control>
                )}
              </div>
              <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {formik.values.packageCategories.map(
                  (packageCategory, index) => (
                    <div
                      key={index}
                      className="p-3 border border-dark rounded mt-2"
                    >
                      <p className="mb-0">{packageCategory.name}</p>
                      {formik.values.packageCategories[index].packages
                        .length === 0 && (
                        <div className="text-danger mb-1">
                          <ExclamationCircle className="mr-2" />
                          <small>Missing session packages</small>
                        </div>
                      )}
                      <FieldArray
                        name={`packageCategories[${index}].packages`}
                        render={(packageArrayHelpers) => (
                          <>
                            {formik.values.packageCategories[
                              index
                            ].packages.map((sessionPackage, i) => {
                              return (
                                <div
                                  key={sessionPackage.id}
                                  className="d-flex align-items-center mt-1"
                                  onClick={() => {
                                    if (canMutate) {
                                      handlePackageRemoveClick(
                                        false,
                                        i,
                                        index,
                                        packageArrayHelpers,
                                        arrayHelpers,
                                        formik
                                      )
                                    }
                                  }}
                                >
                                  {canMutate && (
                                    <Trash className="mr-2 text-danger hover" />
                                  )}
                                  <small>{sessionPackage.title}</small>
                                </div>
                              )
                            })}
                          </>
                        )}
                      />
                      {canMutate && (
                        <div
                          className={
                            formik.values.packageCategories[index].packages
                              .length > 0
                              ? 'mt-2 d-flex align-items-center hover text-danger'
                              : 'd-flex align-items-center hover text-danger'
                          }
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                        >
                          <Trash className="mr-2" />
                          <small>Remove</small>
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </>
          )}
        />
        <ErrorMessage name="packageCategories">
          {(msg) => <span className="text-danger mt-2 d-block">{msg}</span>}
        </ErrorMessage>
      </Form.Group>
    </Form.Row>
  )
}
