import React, { useState, useEffect } from 'react'
import { Form, Col, Button } from 'react-bootstrap'
import { gql, useMutation, useQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import { PlusCircle } from 'react-bootstrap-icons'
import validator from 'validator'

const OrganizationBilling = (props) => {
  const { organizationId } = props
  const [invoiceEmail, setInvoiceEmail] = useState()
  const [stateChanged, setStateChange] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data: getOrgData } = useQuery(
    gql`
      query OrganizationDetail($organizationId: ID!) {
        organization(id: $organizationId) {
          invoiceEmail
          stripeCustomer {
            stripePaymentMethods {
              edges {
                node {
                  id
                  stripeResource
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        organizationId,
      },
    }
  )

  const [updateOrganization] = useMutation(
    gql`
      mutation UpdateOrganizationMutation(
        $updateOrganizationInput: UpdateOrganizationInput!
      ) {
        updateOrganization(input: $updateOrganizationInput) {
          organization {
            id
          }
        }
      }
    `,
    {
      onCompleted() {
        toast.success('Invoice Email Saved')
        setLoading(false)
        setStateChange(false)
      },
      refetchQueries: ['OrganizationDetail'],
    }
  )

  useEffect(() => {
    if (getOrgData) {
      setInvoiceEmail(getOrgData?.organization.invoiceEmail)
    }
  }, [getOrgData])

  return (
    <>
      <Form>
        <Form.Row className="mb-3">
          <Col md={3}>
            <Form.Label>Stripe Customer Email</Form.Label>
            <Form.Control
              type="text"
              name="invoiceEmail"
              className="form-control-sm"
              value={invoiceEmail}
              onChange={(e) => {
                setInvoiceEmail(e.target.value)
                if (!stateChanged) {
                  setStateChange(true)
                }
              }}
            />
          </Col>
          {stateChanged && (
            <Col className="mt-4">
              <Button
                disabled={loading}
                variant="link"
                onClick={() => {
                  if (!validator.isEmail(invoiceEmail)) {
                    toast.error('Invalid Email')
                  } else {
                    setLoading(true)
                    updateOrganization({
                      variables: {
                        updateOrganizationInput: {
                          organizationInput: {
                            id: organizationId,
                            billingInvoiceEmail: invoiceEmail,
                          },
                        },
                      },
                    })
                  }
                }}
              >
                <PlusCircle className="mr-2" /> Save
              </Button>
            </Col>
          )}
        </Form.Row>
      </Form>
    </>
  )
}

export default OrganizationBilling
