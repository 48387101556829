import React, { useState, useEffect } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import {
  Trash,
  PlusCircle,
  ArrowLeft,
  CaretRight,
  CaretDown,
  BoxArrowUpRight,
} from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import * as Yup from 'yup'
import { loggedInUserVar } from '../../libs/apollo'
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import { useHistory } from 'react-router-dom'
import './FormField.css'
import SubjectSearchInput from '../common/node_search_input/SubjectSearchInput'
import JobSearchInput from '../common/node_search_input/JobSearchInput'
import OrganizationSearchInput from '../common/node_search_input/OrganizationSearchInput'
import SubjectGroupSearchInput from '../common/node_search_input/SubjectGroupSearchInput'
import EmployeeSearchInput from '../common/node_search_input/EmployeeSearchInput'
import EmployeeJobSearchInput from '../common/node_search_input/EmployeeJobSearchInput'
import FormSubmissions from './FormSubmissions'

const FormForm = (props) => {
  const { form, afterSubmit, formPage } = props
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [submitting, setSubmitting] = useState(false)
  const [creator, setCreator] = useState(null)
  const [displayFormSubmissions, setDisplayFormSubmissions] = useState(false)

  useEffect(() => {
    const options = {
      showLogicTab: true,
      height: '700px',
    }
    const creatorInstance = new SurveyCreator(options)
    if (form && form.form) {
      const parsedJson = JSON.parse(form.form)
      creatorInstance.JSON = parsedJson
    }
    setCreator(creatorInstance)
  }, [])

  const [deleteForm] = useMutation(
    gql`
      mutation DeleteForm($deleteFormInput: DeleteFormInput!) {
        deleteForm(input: $deleteFormInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Form Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Forms'],
    }
  )

  const [createForm] = useMutation(
    gql`
      mutation CreateForm($input: CreateFormInput!) {
        createForm(input: $input) {
          form {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Form Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Forms'],
    }
  )

  const [updateForm] = useMutation(
    gql`
      mutation UpdateForm($input: UpdateFormInput!) {
        updateForm(input: $input) {
          form {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Form Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Forms'],
    }
  )

  const formik = useFormik({
    initialValues: form
      ? {
          name: form.name,
          public: form.public || false,
          jobId: form.jobId || '',
          organizationId: form.organization?.id || '',
          subjectGroupId: form.subjectGroup?.id || '',
          subjectId: form.subject?.id || '',
          subjectDescription: form.subject?.gaiaUser?.fullName || '',
          jobDescription: form.job?.name || '',
          organizationDescription: form.organization?.name || '',
          subjectGroupDescription: form.subjectGroup?.name || '',
          employeeId: form.employee?.id || '',
          employeeJobId: form.employeeJob?.id || '',
          employeeDescription: form.employee?.gaiaUser?.fullName || '',
          employeeJobDescription: form.employeeJob?.description || '',
        }
      : {
          name: '',
          public: false,
          subjectId: '',
          jobId: '',
          organizationId: '',
          subjectGroupId: '',
          employeeId: '',
          employeeJobId: '',
          employeeDescription: '',
          employeeJobDescription: '',
          jobDescription: '',
          organizationDescription: '',
          subjectGroupDescription: '',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
      subjectId: Yup.string().nullable(),
      public: Yup.boolean(),
      employeeId: Yup.string().nullable(),
      employeeJobId: Yup.string().nullable(),
      jobId: Yup.string().nullable(),
      organizationId: Yup.string().nullable(),
      subjectGroupId: Yup.string().nullable(),
      employeeDescription: Yup.string().nullable(),
      employeeJobDescription: Yup.string().nullable(),
      organizationDescription: Yup.string().nullable(),
      subjectGroupDescription: Yup.string().nullable(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const formJson = JSON.stringify(creator.JSON)
      if (form) {
        updateForm({
          variables: {
            input: {
              formInput: {
                id: form.id,
                name: values.name,
                form: formJson,
                public: values.public,
                subjectId: values.subjectId,
                jobId: values.jobId,
                organizationId: values.organizationId,
                subjectGroupId: values.subjectGroupId,
                employeeId: values.employeeId,
                employeeJobId: values.employeeJobId,
              },
            },
          },
        })
      } else {
        createForm({
          variables: {
            input: {
              formInput: {
                name: values.name,
                form: formJson,
                public: values.public,
                subjectId: values.subjectId,
                jobId: values.jobId,
                organizationId: values.organizationId,
                subjectGroupId: values.subjectGroupId,
                employeeId: values.employeeId,
                employeeJobId: values.employeeJobId,
              },
            },
          },
        })
      }
    },
  })

  const handleSearchInputChange = (field, value) => {
    formik.setFieldValue(field, value)
    const fieldsToReset = [
      'subjectId',
      'jobId',
      'organizationId',
      'subjectGroupId',
    ].filter((f) => f !== field)
    fieldsToReset.forEach((f) => formik.setFieldValue(f, ''))
  }

  const handleDelete = () => {
    setSubmitting(true)
    deleteForm({
      variables: {
        deleteFormInput: {
          ids: [form.id],
        },
      },
    })
  }

  if (!creator) {
    return <Loading />
  }

  return (
    <>
      <div
        id="equipmentBagTypeForm"
        style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <Form
          onSubmit={formik.handleSubmit}
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
          <Form.Group as={Row} style={{ marginLeft: '-25px' }}>
            <Col md={1}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={4}>
              <Form.Control
                name="name"
                className="form-control-sm"
                disabled={!canMutate}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={2}>
              <span
                onClick={() => {
                  formik.setFieldValue(`public`, !formik.values.public)
                }}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '10px 20px',
                }}
              >
                <Form.Check
                  name="public"
                  type="switch"
                  label="Public"
                  checked={formik.values.public}
                  onChange={(e) => {
                    e.stopPropagation()
                    formik.setFieldValue(`public`, e.target.checked)
                  }}
                />
              </span>
            </Col>
          </Form.Group>
          <Form.Row>
            <>
              <Form.Group as={Col} md={3}>
                <Form.Label>Subject</Form.Label>
                <SubjectSearchInput
                  formik={formik}
                  formikValue="subject"
                  error={formik.errors.subjectId}
                  onChange={(value) =>
                    handleSearchInputChange('subjectId', value)
                  }
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>Job</Form.Label>
                <JobSearchInput
                  formik={formik}
                  formikValue="job"
                  error={formik.errors.jobId}
                  onChange={(value) => handleSearchInputChange('jobId', value)}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>Organization</Form.Label>
                <OrganizationSearchInput
                  formik={formik}
                  formikValue="organization"
                  error={formik.errors.organizationId}
                  onChange={(value) =>
                    handleSearchInputChange('organizationId', value)
                  }
                />
              </Form.Group>
            </>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>Subject Group</Form.Label>
              <SubjectGroupSearchInput
                formik={formik}
                formikValue="subjectGroup"
                error={formik.errors.subjectGroupId}
                onChange={(value) =>
                  handleSearchInputChange('subjectGroupId', value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>Employee</Form.Label>
              <EmployeeSearchInput
                formik={formik}
                formikValue="employee"
                error={formik.errors.employeeId}
                onChange={(value) =>
                  handleSearchInputChange('employeeId', value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>Employee Job</Form.Label>
              <EmployeeJobSearchInput
                formik={formik}
                formikValue="employeeJob"
                error={formik.errors.employeeJobId}
                onChange={(value) =>
                  handleSearchInputChange('employeeJobId', value)
                }
              />
            </Form.Group>
          </Form.Row>
          {form && (
            <>
              <Row className="mt-3 mb-2">
                <Col md={12} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() =>
                      setDisplayFormSubmissions(!displayFormSubmissions)
                    }
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayFormSubmissions ? (
                        <>
                          <CaretDown size={15} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={15} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Form Submissions</Form.Label>
                </Col>
              </Row>
              {displayFormSubmissions && <FormSubmissions formId={form.id} />}
            </>
          )}
          <Form.Row style={{ flex: 1 }}>
            <Col>
              <SurveyCreatorComponent
                creator={creator}
                style={{ height: '100%' }}
              />
            </Col>
          </Form.Row>
          {canMutate && (
            <>
              <Row className="mt-3 mb-5">
                {formPage && (
                  <Col md={2}>
                    <Button
                      block
                      variant="outline-primary"
                      onClick={() => history.push(`/forms`)}
                    >
                      <ArrowLeft className="mr-2" />
                      Back
                    </Button>
                  </Col>
                )}
                <Col md={2}>
                  <Button
                    type="submit"
                    block
                    variant="outline-primary"
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>

                {form && (
                  <>
                    <Col md={2}>
                      <Button
                        block
                        variant="outline-primary"
                        disabled={submitting}
                        onClick={() => {
                          window.open(`/form/submit/${form.id}`, '_blank')
                        }}
                      >
                        <BoxArrowUpRight className="mr-2" />
                        Open Form
                      </Button>
                    </Col>
                    <Col md={2}>
                      <Button
                        block
                        variant="outline-danger"
                        onClick={handleDelete}
                        disabled={submitting}
                      >
                        <Trash className="mr-2" />
                        Delete
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              {submitting && (
                <Form.Row className="mt-3">
                  <Col>
                    <Loading message="Saving Form..." />
                  </Col>
                </Form.Row>
              )}
            </>
          )}
        </Form>
      </div>
    </>
  )
}

export default FormForm
