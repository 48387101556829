import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Modal, Button, Table, Dropdown, Form } from 'react-bootstrap'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  Dot,
  CaretDown,
  CaretRight,
  CloudArrowDown,
} from 'react-bootstrap-icons'
import QrReader from 'react-qr-reader'
import { Trash, Search, PlusCircle } from 'react-bootstrap-icons'
import { toast } from 'react-hot-toast'
import BagModal from './Bag/BagModal'
import ItemModal from './Item/ItemModal'
import Loading from '../common/Loading'
import EquipmentBagSearchInput from '../common/node_search_input/EquipmentBagSearchInput'
import EquipmentItemSearchInput from '../common/node_search_input/EquipmentItemSearchInput'
import { useFormik } from 'formik'
import Sound from '../Sound'
import { formatDateTime } from '../../libs/utils'
import * as Yup from 'yup'
import EmployeeSearchInput from '../common/node_search_input/EmployeeSearchInput'
import JobSearchInput from '../common/node_search_input/JobSearchInput'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import EmployeeJobSearchInput from '../common/node_search_input/EmployeeJobSearchInput'
import { useDownloadFile } from '../../libs/downloadFile'

export default function ScanModal(props) {
  const {
    jobForm,
    jobId,
    employeeJobId,
    showModal,
    submitModal,
    toggleModal,
    setScannedItems,
    setScannedBags,
    scannedItems,
    scannedBags,
    submitButtonMessage,
    addItemValidation,
    addBagValidation,
    loading,
    setLoading,
    validateBagReturned,
    validateBagNoEmployee,
    validateItemReturned,
    validateItemUnderRepair,
    validateItemRetire,
    validateItemNoBag,
    validateItemNoEmployee,
    showSubmitValidation,
    itemQuery,
    bagQuery,
    otherButtons,
    hideDropdown,
    employeeId,
  } = props
  const modalRef = useRef(null)
  const { downloadAndDeleteFile } = useDownloadFile()
  const [currentScan, setCurrentScan] = useState()
  const [currentSearch, setCurrentSearch] = useState()
  const [editItem, setEditItem] = useState()
  const [editBag, setEditBag] = useState()
  const [displayScan, setDisplayScan] = useState()
  const [searchResult, setSearchResult] = useState()
  const [playSound, setPlaySound] = useState(false)
  const [employeeJob, setEmployeeJob] = useState()
  const [_loading, _setLoading] = useState(false)

  const [updateEmployeeJob] = useMutation(gql`
    mutation UpdateEmployeeJob($input: UpdateEmployeeJobInput!) {
      updateEmployeeJob(input: $input) {
        employeeJob {
          id
        }
      }
    }
  `)

  const [downloadPdf] = useMutation(
    gql`
      mutation DownloadEquipmentBag($input: DownloadEquipmentBagInput!) {
        downloadEquipmentBag(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        _setLoading(false)
        if (data?.downloadEquipmentBag?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEquipmentBag.file.fileName,
            data.downloadEquipmentBag.file.displayName,
            data.downloadEquipmentBag.file.id,
            () => {
              toast.success(`Bag Downloaded`)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const [sendNotificationTrigger] = useMutation(
    gql`
      mutation SendNotificationTrigger(
        $sendNotificationTriggerInput: SendNotificationTriggerInput!
      ) {
        sendNotificationTrigger(input: $sendNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        toast.success('Sending Notification')
      },
    }
  )

  const [updateItem] = useMutation(
    gql`
      mutation UpdateEquipmentItemMutations(
        $updateEquipmentItemInput: UpdateEquipmentItemInput!
      ) {
        updateEquipmentItem(input: $updateEquipmentItemInput) {
          equipmentItem {
            id
            name
            returned
          }
        }
      }
    `,
    {
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentItems',
      ],
    }
  )

  const [updateBag] = useMutation(
    gql`
      mutation UpdateEquipmentBagMutations(
        $updateEquipmentBagInput: UpdateEquipmentBagInput!
      ) {
        updateEquipmentBag(input: $updateEquipmentBagInput) {
          equipmentBag {
            id
            name
            returned
          }
        }
      }
    `,
    {
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentItems',
      ],
    }
  )

  const [scanItem] = useLazyQuery(
    itemQuery
      ? itemQuery
      : gql`
          query ScanItems($equipmentItemId: [ID]) {
            equipmentItems(id: $equipmentItemId) {
              edges {
                node {
                  id
                  name
                  retire
                  employee {
                    gaiaUser {
                      fullName
                    }
                    id
                  }
                  equipmentBag {
                    id
                  }
                  underRepair
                  returned
                  qrCodeFile {
                    fileName
                    displayName
                    fileUrl
                  }
                  lastEmployeeJob {
                    job {
                      name
                    }
                    startDateTime
                    endDateTime
                    employee {
                      gaiaUser {
                        fullName
                      }
                    }
                  }
                  employeeJobs {
                    edges {
                      node {
                        job {
                          name
                          startDateTime
                          endDateTime
                        }
                      }
                    }
                  }
                  serialNumber
                  equipmentBag {
                    id
                    name
                  }
                  equipmentCategory {
                    id
                    name
                  }
                  equipmentItemType {
                    name
                    id
                  }
                }
              }
            }
          }
        `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data.equipmentItems.edges.length > 0) {
          const node = data.equipmentItems.edges[0].node
          if ([currentScan, currentSearch].includes(node.id)) {
            if (currentScan) {
              setCurrentScan()
            }
            if (currentSearch) {
              setCurrentSearch()
            }
            if (!validateItemUnderRepair && node.underRepair) {
              toast.error('Item Under Repair')
            } else if (validateItemRetire && node.retire) {
              toast.error('Item Retired')
            } else if (validateItemNoBag && node.equipmentBag) {
              toast.error('Item Assigned In Bag')
            } else if (validateItemNoEmployee && node.employee) {
              toast.error('Item Assigned to Employee')
            } else if (validateItemReturned && !node.returned) {
              toast.error('Item Not Returned')
            } else if (
              !addItemValidation ||
              addItemValidation(data.equipmentItems.edges)
            ) {
              if (
                !scannedItems.some(
                  (item) => item.id === data.equipmentItems.edges[0].node.id
                )
              ) {
                if (!searchResult) {
                  setPlaySound(true)
                }
                setScannedItems((items) => [
                  ...items,
                  data.equipmentItems.edges[0].node,
                ])
                if (searchResult) {
                  setSearchResult(false)
                } else {
                  toast.success('Item Scanned')
                }
              } else {
                const updatedId = data.equipmentItems.edges[0].node.id
                setScannedItems((preItems) =>
                  preItems.map((item) =>
                    item.id === updatedId
                      ? data.equipmentItems.edges[0].node
                      : item
                  )
                )
              }
            }
          }
        }
      },
      errorPolicy: 'all',
    }
  )

  const [scanBag] = useLazyQuery(
    bagQuery
      ? bagQuery
      : gql`
          query ScanBags($equipmentBagId: [ID]) {
            equipmentBags(id: $equipmentBagId) {
              edges {
                node {
                  id
                  name
                  returned
                  notes
                  equipmentCategory {
                    id
                    name
                  }
                  qrCodeFile {
                    fileName
                    displayName
                    fileUrl
                  }
                  equipmentBagType {
                    id
                    name
                    equipmentItemTypesList {
                      id
                      name
                      equipmentCategory {
                        id
                        name
                      }
                    }
                  }
                  lastEmployeeJob {
                    job {
                      name
                    }
                    startDateTime
                    endDateTime
                    employee {
                      gaiaUser {
                        fullName
                      }
                    }
                  }
                  employee {
                    gaiaUser {
                      fullName
                    }
                    id
                  }
                  employeeJobs {
                    edges {
                      node {
                        job {
                          name
                          startDateTime
                          endDateTime
                        }
                      }
                    }
                  }
                  equipmentItems {
                    edges {
                      node {
                        id
                        name
                        serialNumber
                        equipmentBag {
                          id
                          name
                        }
                        equipmentCategory {
                          id
                          name
                        }
                        equipmentItemType {
                          name
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data.equipmentBags.edges.length > 0) {
          const node = data.equipmentBags.edges[0].node
          if ([currentScan, currentSearch].includes(node.id)) {
            if (currentScan) {
              setCurrentScan()
            }
            if (currentSearch) {
              setCurrentSearch()
            }
            if (validateBagReturned && !node.returned) {
              toast.error('Bag Not Returned')
            } else if (validateBagNoEmployee && node.employee) {
              toast.error('Bag Assigned to Employee')
            } else if (
              !addBagValidation ||
              addBagValidation(data.equipmentBags.edges)
            ) {
              if (
                !scannedBags.some(
                  (item) => item.id === data.equipmentBags.edges[0].node.id
                )
              ) {
                if (!searchResult) {
                  setPlaySound(true)
                }
                setScannedBags((bags) => [
                  ...bags,
                  data.equipmentBags.edges[0].node,
                ])
                if (searchResult) {
                  setSearchResult(false)
                } else {
                  toast.success('Bag Scanned')
                }
              } else {
                const updatedId = data.equipmentBags.edges[0].node.id
                setScannedBags((prevBags) =>
                  prevBags.map((bag) =>
                    bag.id === updatedId
                      ? data.equipmentBags.edges[0].node
                      : bag
                  )
                )
              }
            }
          }
        }
      },
      errorPolicy: 'all',
    }
  )

  const handleScan = (value) => {
    if (value) {
      setCurrentScan(value)
      scanBag({
        variables: {
          equipmentBagId: [value],
        },
      })
      scanItem({
        variables: {
          equipmentItemId: [value],
        },
      })
    } else {
      setCurrentScan()
    }
  }

  const [fetchEmployeeJob] = useLazyQuery(
    gql`
      query EmployeeJobsQuery($id: [ID]) {
        employeeJobs(id: $id) {
          edges {
            node {
              id
              equipmentPickupArea
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data.employeeJobs.edges.length > 0) {
          const node = data.employeeJobs.edges[0].node
          formik.setFieldValue('equipmentPickupArea', node.equipmentPickupArea)
          setEmployeeJob(node)
        }
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      selectedScanType: 'Check In / Out',
      startDate: new Date(),
      bagId: '',
      itemId: '',
      employeeId: '',
      jobId: jobId || '',
      employeeJobId: employeeJobId || '',
      equipmentPickupArea: '',
      sendEquipmentNotification: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      selectedScanType: Yup.string().required('Scan type is required'),
      bagId: Yup.string(),
      itemId: Yup.string(),
      employeeId: Yup.string().test(
        'employee-id-required',
        'required',
        (value, context) => {
          let result = true
          const { selectedScanType } = context.parent
          if (selectedScanType === 'Assign To Employee' && !value) {
            result = false
          }
          return result
        }
      ),
      jobId: Yup.string().test(
        'job-id-required',
        'required',
        (value, context) => {
          let result = true
          const { selectedScanType } = context.parent
          if (selectedScanType === 'Assign To Employee Job' && !value) {
            result = false
          }

          return result
        }
      ),
      employeeJobId: Yup.string().test(
        'employee-job-id-required',
        'required',
        (value, context) => {
          let result = true
          const { selectedScanType } = context.parent
          if (selectedScanType === 'Assign To Employee Job' && !value) {
            result = false
          }

          return result
        }
      ),
    }),
    onSubmit: async (values) => {
      if (setLoading) {
        setLoading(true)
      }
      _setLoading(true)
      const variables = {}
      if (values.selectedScanType === 'Assign To Employee') {
        variables.employeeId = values.employeeId
      }
      if (values.selectedScanType === 'Assign To Employee Job') {
        variables.employeeJobId = values.employeeJobId
      }

      scannedItems.forEach((item) => {
        updateItem({
          variables: {
            updateEquipmentItemInput: {
              equipmentItemInput: {
                id: item.id,
                ...variables,
              },
            },
          },
        })
      })
      scannedBags.forEach((bag) => {
        updateBag({
          variables: {
            updateEquipmentBagInput: {
              equipmentBagInput: {
                id: bag.id,
                ...variables,
              },
            },
          },
        })
      })
      toast.success('Equipment Saved')
      _setLoading(false)
      if (setLoading) {
        setLoading(false)
      }
      if (values.employeeJobId && values.sendEquipmentNotification) {
        sendNotificationTrigger({
          variables: {
            sendNotificationTriggerInput: {
              notificationTriggerInput: {
                sendToEmployeeJobId: values.employeeJobId,
              },
            },
          },
        })
      }

      if (
        values.employeeJobId &&
        values.equipmentPickupArea &&
        employeeJob?.equipmentPickupArea !== values.equipmentPickupArea
      ) {
        updateEmployeeJob({
          variables: {
            input: {
              employeeJobInput: {
                id: values.employeeJobId,
                equipmentPickupArea: values.equipmentPickupArea,
              },
            },
          },
        })
      }
      handleClose()
    },
  })

  useEffect(() => {
    if (employeeId) {
      formik.setFieldValue('employeeId', employeeId)
      formik.setFieldValue('selectedScanType', 'Assign To Employee')
    }
  }, [employeeId])

  useEffect(() => {
    if (formik.values.bagId) {
      setSearchResult(true)
      setCurrentSearch(formik.values.bagId)
      scanBag({
        variables: {
          equipmentBagId: [formik.values.bagId],
        },
      })
      formik.setFieldValue('bagId', '')
      formik.setFieldValue('bagDescription', '')
    }
    if (formik.values.itemId) {
      setSearchResult(true)
      setCurrentSearch(formik.values.itemId)
      scanItem({
        variables: {
          equipmentItemId: [formik.values.itemId],
        },
      })
      formik.setFieldValue('itemId', '')
      formik.setFieldValue('itemDescription', '')
    }
  }, [formik.values.bagId, formik.values.itemId])

  useEffect(() => {
    if (formik.values.employeeJobId) {
      fetchEmployeeJob({
        variables: {
          id: [formik.values.employeeJobId],
        },
      })
    }
  }, [formik.values.employeeJobId])

  useEffect(() => {
    if (scannedBags) {
      setScannedBags([])
    }
    if (scannedItems) {
      setScannedItems([])
    }
  }, [formik.values.selectedScanType])

  useEffect(() => {
    if (playSound) {
      setTimeout(() => setPlaySound(false), 500)
    }
  }, [playSound])

  const handleClose = () => {
    formik.resetForm()
    toggleModal()
  }
  return (
    <>
      {playSound && <Sound sound="success" />}
      <Modal show={showModal} onHide={handleClose} animation={false} size="lg">
        <div ref={modalRef}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Equipment</Modal.Title>
          </Modal.Header>
          <Modal.Body ref={modalRef}>
            <Row>
              {!hideDropdown && (
                <Row>
                  <Col className="d-flex align-items-center">
                    <span className="btn-link p-0">
                      <Dropdown align="start">
                        <Dropdown.Toggle
                          variant="link"
                          id="filter-dropdown"
                          style={{ padding: 0, margin: 0 }}
                        >
                          {formik.values.selectedScanType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {[
                            'Check In / Out',
                            'Assign To Employee',
                            'Assign To Employee Job',
                          ].map((item) => {
                            return (
                              <Dropdown.Item
                                key={item}
                                onClick={() => {
                                  formik.setFieldValue('selectedScanType', item)
                                }}
                              >
                                {item}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </Col>
                </Row>
              )}
            </Row>
            {formik.values.selectedScanType === 'Assign To Employee Job' && (
              <>
                <Row className="mt-3">
                  <Col md={3}>
                    <DatePicker
                      name="startDate"
                      className="form-control form-control-sm"
                      showPopperArrow={false}
                      popperPlacement="auto"
                      selected={formik.values.startDate}
                      onChange={(date) =>
                        formik.setFieldValue('startDate', date)
                      }
                      popperModifiers={{
                        flip: {
                          behavior: ['bottom'],
                        },
                        preventOverflow: {
                          enabled: false,
                        },
                        hide: {
                          enabled: false,
                        },
                      }}
                    />
                  </Col>
                  <Col md={6}>Job Date</Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <JobSearchInput
                      dropdown
                      formik={formik}
                      placeholder="Select Job"
                      disabled={!formik.values.startDate}
                      error={formik.errors.jobId}
                      variables={{
                        startDateTime: moment(formik.values.startDate)
                          .startOf('day')
                          .toISOString(true),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <EmployeeJobSearchInput
                      dropdown
                      formik={formik}
                      placeholder={
                        formik.values.jobId ? 'Select Employee Job' : '- - -'
                      }
                      disabled={!formik.values.jobId}
                      error={formik.errors.employeeJobId}
                      variables={{ job: formik.values.jobId }}
                      formatDescription={(node) => {
                        return `${node.employee ? node.employee.gaiaUser.fullName : 'Unassigned'} - ${node?.role?.name} `
                      }}
                      query={gql`
                        query EmployeeJobs(
                          $first: Int
                          $after: String
                          $search: String
                          $job: ID
                        ) {
                          employeeJobs(
                            first: $first
                            after: $after
                            search: $search
                            orderBy: "job__start_date_time"
                            job: $job
                          ) {
                            nodeCount
                            pageInfo {
                              hasNextPage
                              endCursor
                            }
                            edges {
                              node {
                                id
                                role {
                                  name
                                }
                                employee {
                                  gaiaUser {
                                    fullName
                                  }
                                }
                              }
                            }
                          }
                        }
                      `}
                    />
                  </Col>
                </Row>
              </>
            )}
            {!employeeId &&
              formik.values.selectedScanType === 'Assign To Employee' && (
                <Row className="mt-3">
                  <Col md={6}>
                    <EmployeeSearchInput
                      formik={formik}
                      error={formik.errors.employeeId}
                    />
                  </Col>
                </Row>
              )}
            <Row className="mt-3">
              <Col>
                <Row>
                  <Col md={6}>
                    <EquipmentBagSearchInput
                      query={gql`
                        query SearchBags(
                          $first: Int
                          $after: String
                          $search: String
                          $employeeIsNull: Boolean
                          $excludeAssignedEmployeeJob: String
                        ) {
                          equipmentBags(
                            first: $first
                            after: $after
                            orderBy: "name"
                            name_Icontains: $search
                            employee_Isnull: $employeeIsNull
                            excludeAssignedEmployeeJob: $excludeAssignedEmployeeJob
                          ) {
                            nodeCount
                            pageInfo {
                              hasNextPage
                              endCursor
                            }
                            edges {
                              node {
                                id
                                name
                                nextEmployeeJob {
                                  job {
                                    name
                                  }
                                  startDateTime
                                  endDateTime
                                  employee {
                                    gaiaUser {
                                      fullName
                                    }
                                  }
                                }
                                lastEmployeeJob {
                                  job {
                                    name
                                  }
                                  startDateTime
                                  endDateTime
                                  employee {
                                    gaiaUser {
                                      fullName
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      `}
                      formik={formik}
                      error={formik.errors.bagId}
                      disabled={
                        formik.values.selectedScanType ===
                        'Assign To Employee Job'
                          ? formik.values.jobId && formik.values.employeeJobId
                            ? false
                            : true
                          : false
                      }
                      variables={
                        formik.values.selectedScanType === 'Assign To Employee'
                          ? { employeeIsNull: true }
                          : formik.values.selectedScanType ===
                              'Assign To Employee Job'
                            ? {
                                excludeAssignedEmployeeJob:
                                  formik.values.employeeJobId,
                              }
                            : {}
                      }
                    />
                  </Col>
                </Row>
                {scannedBags && scannedBags.length > 0 && (
                  <Row>
                    <Col>
                      <div
                        style={{
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                        }}
                        className="mt-2"
                      >
                        <Table className="table-bordered compact-table ">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Checked In</th>
                              <th>Last Assignment</th>
                              <th>Next Assignment</th>
                              <th>View</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {scannedBags.map((bag, index) => (
                              <tr key={index}>
                                <td>{bag.name}</td>
                                <td>
                                  {bag.equipmentBagType?.name ? (
                                    <span>{bag.equipmentBagType.name}</span>
                                  ) : null}
                                </td>
                                <td>
                                  <span>{bag.returned ? 'Yes' : 'No'}</span>
                                </td>
                                <td>
                                  {bag.lastEmployeeJob && (
                                    <div style={{ fontSize: '12px' }}>
                                      <Dot className="mr-2" />
                                      Job - {bag.lastEmployeeJob.job.name}
                                      <br />
                                      <Dot className="mr-2" />
                                      Employee -{' '}
                                      {bag.lastEmployeeJob.employee ? (
                                        <>
                                          {
                                            bag.lastEmployeeJob.employee
                                              .gaiaUser.fullName
                                          }
                                        </>
                                      ) : (
                                        <>Unassigned</>
                                      )}
                                      <br />
                                      <Dot className="mr-2" />
                                      Shift -{' '}
                                      {formatDateTime(
                                        bag.lastEmployeeJob.startDateTime
                                      )}{' '}
                                      -{' '}
                                      {formatDateTime(
                                        bag.lastEmployeeJob.endDateTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {bag.nextEmployeeJob && (
                                    <div style={{ fontSize: '12px' }}>
                                      <Dot className="mr-2" />
                                      Employee -{' '}
                                      {bag.nextEmployeeJob.employee ? (
                                        <>
                                          {
                                            bag.nextEmployeeJob.employee
                                              .gaiaUser.fullName
                                          }
                                        </>
                                      ) : (
                                        <>Unassigned</>
                                      )}
                                      <br />
                                      <Dot className="mr-2" />
                                      Job - {bag.nextEmployeeJob.job.name}
                                      <br />
                                      <Dot className="mr-2" />
                                      Shift -{' '}
                                      {formatDateTime(
                                        bag.nextEmployeeJob.startDateTime
                                      )}{' '}
                                      -{' '}
                                      {formatDateTime(
                                        bag.nextEmployeeJob.endDateTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    variant="link"
                                    className="ml-2"
                                    onClick={() => {
                                      setEditBag(bag)
                                    }}
                                  >
                                    <Search />
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    variant="link"
                                    className="ml-1"
                                    onClick={() => {
                                      setScannedBags((prevBags) => {
                                        const newBags = [...prevBags]
                                        newBags.splice(index, 1)
                                        return newBags
                                      })
                                    }}
                                  >
                                    <Trash />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Row>
                  <Col md={6}>
                    <EquipmentItemSearchInput
                      formik={formik}
                      error={formik.errors.itemId}
                      disabled={
                        formik.values.selectedScanType ===
                        'Assign To Employee Job'
                          ? formik.values.jobId && formik.values.employeeJobId
                            ? false
                            : true
                          : false
                      }
                      variables={
                        formik.values.selectedScanType === 'Assign To Employee'
                          ? { employeeIsNull: true }
                          : formik.values.selectedScanType ===
                              'Assign To Employee Job'
                            ? {
                                excludeAssignedEmployeeJob:
                                  formik.values.employeeJobId,
                              }
                            : {}
                      }
                    />
                  </Col>
                </Row>
                {scannedItems && scannedItems.length > 0 && (
                  <Row>
                    <Col>
                      <div
                        style={{
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                        }}
                        className="mt-2"
                      >
                        <Table className="table-bordered compact-table ">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Checked In</th>
                              <th>Last Assignment</th>
                              <th>View</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {scannedItems.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {item.name}
                                  {item.serialNumber && (
                                    <> #{item.serialNumber}</>
                                  )}
                                </td>
                                <td>
                                  {item.equipmentItemType?.name ? (
                                    <span>{item.equipmentItemType.name}</span>
                                  ) : null}
                                </td>
                                <td>
                                  <span>{item.returned ? 'Yes' : 'No'}</span>
                                </td>
                                <td>
                                  {item.lastEmployeeJob && (
                                    <div style={{ fontSize: '12px' }}>
                                      <Dot className="mr-2" />
                                      Job - {item.lastEmployeeJob.job.name}
                                      <br />
                                      <Dot className="mr-2" />
                                      Employee -{' '}
                                      {item.lastEmployeeJob.employee ? (
                                        <>
                                          {
                                            item.lastEmployeeJob.employee
                                              .gaiaUser.fullName
                                          }
                                        </>
                                      ) : (
                                        <>Unassigned</>
                                      )}
                                      <br />
                                      <Dot className="mr-2" />
                                      Shift -{' '}
                                      {formatDateTime(
                                        item.lastEmployeeJob.startDateTime
                                      )}{' '}
                                      -{' '}
                                      {formatDateTime(
                                        item.lastEmployeeJob.endDateTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    variant="link"
                                    className="ml-2"
                                    onClick={() => {
                                      setEditItem(item)
                                    }}
                                  >
                                    <Search />
                                  </Button>
                                </td>
                                <td>
                                  <span className="btn-link">
                                    <Trash
                                      onClick={() => {
                                        setScannedItems((prevItems) => {
                                          const newItems = [...prevItems]
                                          newItems.splice(index, 1)
                                          return newItems
                                        })
                                      }}
                                      className="ml-1 mt-1"
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {formik.values.selectedScanType === 'Assign To Employee Job' && (
              <>
                <Row className="mt-3">
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      disabled={
                        !formik.values.jobId && !formik.values.employeeJobId
                      }
                      placeholder="Equipment Pickup Area"
                      name={'equipmentPickupArea'}
                      value={formik.values.equipmentPickupArea}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'equipmentPickupArea',
                          e.target.value
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Check
                      inline
                      disabled={
                        !formik.values.jobId && !formik.values.employeeJobId
                      }
                      className="small label-bold mb-0 ms-2"
                      type="checkbox"
                      checked={formik.values.sendEquipmentNotification}
                      label="Send Equipment Notification"
                      onChange={(e) => {
                        formik.setFieldValue(
                          'sendEquipmentNotification',
                          e.target.checked
                        )
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Form.Group as={Row} className="mt-3">
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayScan(!displayScan)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayScan ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label column sm="12" md="auto">
                  Scan
                </Form.Label>
              </Col>
            </Form.Group>
            {displayScan && (
              <Col md={{ span: 6, offset: 3 }}>
                <QrReader
                  delay={300}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              </Col>
            )}

            {(!showSubmitValidation || showSubmitValidation()) &&
              formik.values.selectedScanType === 'Check In / Out' && (
                <Row className="mt-3">
                  <Col md={{ span: 6, offset: 3 }}>
                    <Button
                      block
                      disabled={_loading || loading}
                      variant="outline-primary"
                      onClick={submitModal}
                    >
                      <PlusCircle className="mr-2" />
                      {submitButtonMessage ? (
                        <>{submitButtonMessage}</>
                      ) : (
                        <>Done</>
                      )}
                    </Button>
                  </Col>
                </Row>
              )}
            {otherButtons &&
              formik.values.selectedScanType === 'Check In / Out' &&
              otherButtons()}
            {formik.values.selectedScanType !== 'Check In / Out' && (
              <Row className="mt-3">
                <Col md={{ span: 6, offset: 3 }}>
                  <Button
                    block
                    disabled={
                      scannedBags.length === 0 && scannedItems.length === 0
                    }
                    variant="outline-primary"
                    onClick={() => formik.handleSubmit()}
                  >
                    <PlusCircle className="mr-2" />
                    Assign
                  </Button>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col md={{ span: 6, offset: 3 }}>
                <Button
                  block
                  disabled={
                    scannedBags.length === 0 ||
                    _loading ||
                    loading ||
                    (formik.values.selectedScanType ===
                      'Assign To Employee Job' &&
                      (!formik.values.jobId || !formik.values.employeeJobId)) ||
                    (formik.values.selectedScanType === 'Assign To Employee' &&
                      !formik.values.employeeId)
                  }
                  variant="outline-primary"
                  onClick={() => {
                    _setLoading(true)
                    downloadPdf({
                      variables: {
                        input: {
                          equipmentBagIds: scannedBags.map((bag) => bag.id),
                          employeeJobId:
                            formik.values.selectedScanType ===
                              'Assign To Employee Job' || jobForm
                              ? formik.values.employeeJobId
                              : null,
                          jobId:
                            formik.values.selectedScanType ===
                              'Assign To Employee Job' || jobForm
                              ? formik.values.jobId
                              : null,
                          employeeId:
                            formik.values.selectedScanType ===
                            'Assign To Employee'
                              ? formik.values.employeeId
                              : null,
                          type: 'pdf',
                        },
                      },
                    })
                  }}
                >
                  <CloudArrowDown className="mr-2" />
                  Download
                </Button>
              </Col>
            </Row>
            {(_loading || loading) && (
              <div className="mt-2">
                <Loading />
              </div>
            )}
          </Modal.Body>
        </div>
      </Modal>
      {editItem && (
        <ItemModal item={editItem} showModal={true} toggleModal={setEditItem} />
      )}
      {editBag && (
        <BagModal bag={editBag} showModal={true} toggleModal={setEditBag} />
      )}
    </>
  )
}
