import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import { Trash } from 'react-bootstrap-icons'
import Loading from '../common/Loading'

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      deleted
    }
  }
`

const DeleteCommentModal = ({ showModal, toggleModal, commentId, refetch }) => {
  const [submitting, setSubmitting] = useState(false)

  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION, {
    onCompleted: () => {
      setSubmitting(false)
      toast.success('Comment Deleted')
      toggleModal()
      refetch()
    },
    errorPolicy: 'all',
  })

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteComment({
      variables: {
        input: {
          commentIds: [commentId],
        },
      },
    })
  }

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Delete Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="text-center">
            <p style={{ fontSize: '1rem' }}>
              Are you sure you want to delete the comment?
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" md={{ span: 10, offset: 1 }}>
            <Button
              variant="outline-danger"
              size="sm"
              block
              disabled={submitting}
              onClick={handleDeleteClick}
            >
              <Trash className="mr-2" />
              Delete
            </Button>
          </Col>
        </Row>
        {submitting && <Loading />}
      </Modal.Body>
    </Modal>
  )
}

export default DeleteCommentModal
