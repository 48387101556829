import React, { useState } from 'react'
import { Row, Col, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTable, useSortBy, useGroupBy, useExpanded } from 'react-table'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Dot } from 'react-bootstrap-icons'
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import './JobsList.css'
import { DateTime } from 'luxon'
import moment from 'moment'
import { FileEarmarkText } from 'react-bootstrap-icons'
import EmployeeAssignmentsModal from './job/EmployeeAssignmentsModal'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import FilesModal from '../files/box/BoxFilesModal'

const JobsList = (props) => {
  const {
    jobsQueryData,
    jobs,
    setPollInterval,
    hasMoreJobs,
    fetchMoreJobs,
    setJobDetailId,
    jobDetailId,
    toggleJobDetailModal,
    showEquipmentFilter,
    startDateFilter,
    setPrevNextEndDateFilter,
    contact,
    setPrevNextDateClick,
    setPrevNextStartDateFilter,
    setJobDetailOnlySessions,
    tableHeight,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [boxFolderId, setBoxFolderId] = useState()
  const [boxFolderDescrition, setBoxFolderDescription] = useState()
  const todayDate = DateTime.now().toFormat('EEEE, MMMM d, yyyy')

  const [showEmployeeAssignmentsModal, setShowEmployeeAssignmentsModal] =
    useState(false)
  const sortJobDateTime = (rowOne, rowTwo, id, desc) => {
    const rowOneStartDateTime = new Date(rowOne.original.startDateTime)
    const rowTwoStartDateTime = new Date(rowTwo.original.startDateTime)
    if (desc) {
      if (rowOneStartDateTime > rowTwoStartDateTime) {
        return 1
      }
      return -1
    }
    if (rowOneStartDateTime > rowTwoStartDateTime) {
      return 1
    }
    return -1
  }

  const getBgColor = (jobTypeBgColor, stageBgColor) => {
    if (jobTypeBgColor) {
      return jobTypeBgColor
    } else if (settings.jobStages && stageBgColor) {
      return stageBgColor
    }
    return ''
  }
  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'on',
        sortType: sortJobDateTime,
      },
      {
        Header: 'Time',
        accessor: (response) => {
          let output = []
          response.data.forEach((each_data) => {
            output.push(each_data.jobTime)
          })
          return output.join('\n,')
        },
      },
      {
        Header: 'Name',
        accessor: (response) => {
          let output = []
          response.data.forEach((each_data) => {
            output.push(each_data.name)
          })
          return output.join('\n,')
        },
      },
      {
        Header: 'Bookable',
        accessor: (response) => {
          let output = []
          response.data.forEach((each_data) => {
            output.push(
              `${each_data.totalBookable}-${each_data.totalSubjectGroups}`
            )
          })
          return output.join('\n,')
        },
      },
      {
        Header: 'Location',
        accessor: (response) => {
          let output = []
          response.data.forEach((each_data) => {
            output.push(each_data.location)
          })
          return output.join('\n|')
        },
      },
      {
        Header: 'Sessions',
        accessor: (response) => {
          let output = []
          response.data.forEach((each_data) => {
            output.push(each_data.sessions)
          })
          return output.join('\n,')
        },
      },
      {
        Header: 'Booked',
        accessor: () => {},
      },
    ],
    []
  )
  if (!contact) {
    tableColumns.splice(5, 0, {
      Header: 'Employees',
      accessor: (response) => {
        let output = []
        response.data.forEach((each_data) => {
          each_data.employees.forEach((each_emp) => {
            output.push(each_emp)
          })
        })
        return output
      },
    })
  }
  if (settings?.jobStages) {
    tableColumns.splice(3, 0, {
      Header: 'Stage',
      accessor: (response) => {
        let output = []
        response.data.forEach((each_data) => {
          output.push(each_data.stage ? each_data.stage.name : '')
        })
        return output
      },
    })
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data: jobs,
      },
      useGroupBy,
      useSortBy,
      useExpanded
    )

  const constructTableCell = (cell, index) => {
    if (cell.column.id === 'on') {
      const jobTimes = cell.value.substring(cell.value.indexOf(':') + 1)
      return (
        <td
          className="job-list-not-allowed"
          key={index}
          {...cell.getCellProps()}
          style={todayDate === jobTimes ? { backgrounColor: 'lightgreen' } : {}}
        >
          <small className="mb-0 p-0">
            <span className="font-weight-bold">{jobTimes}</span>
          </small>
        </td>
      )
    } else if (
      (contact && cell.column.id === 'Studio Staff') ||
      (!contact && cell.column.id === 'Employees')
    ) {
      if (cell.row.original.data.length > 1) {
        let html = {}
        {
          cell.row.original.data.forEach((each_job, index) => {
            html[index] = []
            if (each_job.employees.length > 0) {
              each_job.employees.forEach((each_emp) => {
                let employeeAssignment
                let employeeRole = each_emp.node.role
                let roleName = 'Unassigned'
                if (employeeRole) {
                  if (employeeRole.abbreviatedName) {
                    roleName = employeeRole.abbreviatedName
                  } else {
                    roleName = employeeRole.name
                  }
                }
                if (each_emp.node.employee) {
                  const gaiaUserNode = each_emp.node.employee.gaiaUser
                  let employeeName
                  if (gaiaUserNode.abbreviatedName) {
                    employeeName = gaiaUserNode.abbreviatedName
                  } else {
                    employeeName = `${
                      gaiaUserNode.firstName
                    } ${gaiaUserNode.lastName.charAt(0)}`
                  }
                  employeeAssignment = `${employeeName} (${roleName})`
                } else {
                  if (roleName === 'Unassigned') {
                    employeeAssignment = 'Unassigned Employee and Role'
                  } else {
                    employeeAssignment = `Unassigned (${roleName})`
                  }
                }
                let shift
                const startDateTime = each_emp.node.startDateTime
                  ? DateTime.fromISO(each_emp.node.startDateTime).toFormat(
                      'h:mma'
                    )
                  : ''
                const endDateTime = each_emp.node.endDateTime
                  ? DateTime.fromISO(each_emp.node.endDateTime).toFormat(
                      'h:mma'
                    )
                  : ''
                if (startDateTime) {
                  shift = `${startDateTime}`
                }
                if (endDateTime) {
                  if (shift) {
                    shift = `${shift}-${endDateTime}`
                  } else {
                    shift = `End ${endDateTime}`
                  }
                } else if (shift) {
                  shift = `Start ${shift}`
                }
                employeeAssignment = `${employeeAssignment} ${
                  shift ? `(${shift})` : '(No Shift)'
                }`
                html[index].push(employeeAssignment)
              })
            }
          })
        }
        if (html) {
          return (
            <td
              key={index}
              {...cell.getCellProps()}
              style={{ cursor: 'pointer' }}
            >
              {Object.values(html).map((employeeNames, employeeNamesIndex) => {
                const jobId = cell.row.original.data[employeeNamesIndex].id
                const bgColor = getBgColor(
                  cell.row.original.data[employeeNamesIndex].jobTypeColor,
                  cell.row.original.data[employeeNamesIndex].stage?.color
                )
                let employee
                employeeNames.forEach((eachEmpName) => {
                  if (!employee) {
                    employee = eachEmpName
                  }
                })
                return (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={
                      <Tooltip style={{ width: '200px' }}>
                        {employeeNames.map((eachEmpName, eachEmpNameIndex) => (
                          <>
                            <small key={eachEmpNameIndex} className="d-block">
                              <Dot /> {eachEmpName}
                            </small>
                          </>
                        ))}
                      </Tooltip>
                    }
                  >
                    <div
                      style={{ background: bgColor }}
                      key={employeeNamesIndex}
                      onClick={() => handleEmployeeDetailClick(jobId)}
                    >
                      {employeeNames.map((eachEmpName, eachEmpNameIndex) => {
                        return (
                          <>
                            <small key={eachEmpNameIndex} className="d-block">
                              {eachEmpName}
                            </small>
                          </>
                        )
                      })}
                    </div>
                  </OverlayTrigger>
                )
              })}
            </td>
          )
        } else {
          return <td key={index} {...cell.getCellProps()}></td>
        }
      } else {
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        return (
          <td
            className="job-hover"
            key={index}
            style={{ background: bgColor, cursor: 'pointer' }}
            {...cell.getCellProps()}
            onClick={() =>
              handleEmployeeDetailClick(cell.row.original.data[0].id)
            }
          >
            <OverlayTrigger
              key="left"
              placement="left"
              overlay={
                <Tooltip style={{ width: '200px' }}>
                  {cell.value.map((employeeJob, eachEmpNameIndex) => {
                    const employeeJobNode = employeeJob.node
                    let roleName
                    if (employeeJobNode.role) {
                      if (employeeJobNode.role.abbreviatedName) {
                        roleName = employeeJobNode.role.abbreviatedName
                      } else {
                        roleName = employeeJobNode.role.name
                      }
                    } else {
                      roleName = 'Unassigned'
                    }
                    let employeeAssignment = 'Unassigned'
                    if (employeeJobNode.employee) {
                      const gaiaUserNode = employeeJobNode.employee.gaiaUser
                      if (gaiaUserNode.abbreviatedName) {
                        employeeAssignment = gaiaUserNode.abbreviatedName
                      } else {
                        employeeAssignment = `${
                          gaiaUserNode.firstName
                        } ${gaiaUserNode.lastName.charAt(0)}`
                      }
                    }
                    if (
                      roleName === 'Unassigned' &&
                      employeeAssignment == 'Unassigned'
                    ) {
                      employeeAssignment = 'Unassigned Employee and Role'
                      roleName = null
                    }
                    let shift
                    const startDateTime = employeeJobNode.startDateTime
                      ? DateTime.fromISO(
                          employeeJobNode.startDateTime
                        ).toFormat('h:mma')
                      : ''
                    const endDateTime = employeeJobNode.endDateTime
                      ? DateTime.fromISO(employeeJobNode.endDateTime).toFormat(
                          'h:mma'
                        )
                      : ''
                    if (startDateTime) {
                      shift = `${startDateTime}`
                    }
                    if (endDateTime) {
                      if (shift) {
                        shift = `${shift}-${endDateTime}`
                      } else {
                        shift = `End ${endDateTime}`
                      }
                    } else if (shift) {
                      shift = `Start ${shift}`
                    }
                    return (
                      <>
                        <small key={eachEmpNameIndex} className="d-block">
                          <Dot />
                          {employeeAssignment} {roleName && <>({roleName})</>}{' '}
                          {shift ? <>({shift})</> : <>(No Shift)</>}
                        </small>
                      </>
                    )
                  })}
                </Tooltip>
              }
            >
              <div>
                {cell.value.map((employeeJob, employeeJobIndex) => {
                  const employeeJobNode = employeeJob.node
                  let roleName

                  if (employeeJobNode.role) {
                    if (employeeJobNode.role.abbreviatedName) {
                      roleName = employeeJobNode.role.abbreviatedName
                    } else {
                      roleName = employeeJobNode.role.name
                    }
                  } else {
                    roleName = 'Unassigned'
                  }
                  let employeeAssignment = 'Unassigned'
                  if (employeeJobNode.employee) {
                    const gaiaUserNode = employeeJobNode.employee.gaiaUser
                    let employeeName
                    if (gaiaUserNode.abbreviatedName) {
                      employeeName = gaiaUserNode.abbreviatedName
                    } else {
                      employeeName = `${
                        gaiaUserNode.firstName
                      } ${gaiaUserNode.lastName.charAt(0)}`
                    }
                    employeeAssignment = employeeName
                  }
                  if (
                    roleName === 'Unassigned' &&
                    employeeAssignment == 'Unassigned'
                  ) {
                    employeeAssignment = 'Unassigned Employee and Role'
                    roleName = null
                  }
                  let shift
                  const startDateTime = employeeJobNode.startDateTime
                    ? DateTime.fromISO(employeeJobNode.startDateTime).toFormat(
                        'h:mma'
                      )
                    : ''
                  const endDateTime = employeeJobNode.endDateTime
                    ? DateTime.fromISO(employeeJobNode.endDateTime).toFormat(
                        'h:mma'
                      )
                    : ''
                  if (startDateTime) {
                    shift = `${startDateTime}`
                  }
                  if (endDateTime) {
                    if (shift) {
                      shift = `${shift}-${endDateTime}`
                    } else {
                      shift = `End ${endDateTime}`
                    }
                  } else if (shift) {
                    shift = `Start ${shift}`
                  }
                  return (
                    <div key={employeeJobIndex} style={{ background: bgColor }}>
                      <small className="d-block">
                        <span>
                          {employeeAssignment} {roleName && <>({roleName})</>} (
                          {shift ? shift : <>No Shift</>})
                        </span>
                      </small>
                    </div>
                  )
                })}
              </div>
            </OverlayTrigger>
          </td>
        )
      }
    } else if (cell.column.id === 'Time') {
      if (cell.value.indexOf(',') > -1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '150px' }}>
            {cell.value.split(',').map((eachJobTime, eachJobIndex) => {
              const jobId = cell.row.original.data[eachJobIndex].id
              const cancelled = cell.row.original.data[eachJobIndex].cancelled
              const bgColor = getBgColor(
                cell.row.original.data[eachJobIndex].jobTypeColor,
                cell.row.original.data[eachJobIndex].stage?.color
              )
              return (
                <div
                  style={{ background: bgColor }}
                  className="job-hover"
                  key={eachJobIndex}
                  onClick={() => handleJobDetailClick(jobId)}
                >
                  <small key={eachJobIndex}>
                    <span
                      className={eachJobIndex === 0 ? '' : ''}
                      key={eachJobIndex}
                    >
                      {eachJobTime} {cancelled && <>CANCELLED</>}
                    </span>
                  </small>
                </div>
              )
            })}
          </td>
        )
      } else {
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        const cancelled = cell.row.original.data[0].cancelled
        return (
          <td
            style={{ background: bgColor, width: '150px' }}
            className="job-hover"
            key={index}
            {...cell.getCellProps()}
            onClick={() => handleJobDetailClick(cell.row.original.data[0].id)}
          >
            {
              <small>
                <span key={index}>
                  {cell.value} {cancelled && <>CANCELLED</>}
                </span>
              </small>
            }
          </td>
        )
      }
    } else if (cell.column.id === 'Files') {
      if (cell.value.indexOf(',') > -1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '150px' }}>
            {cell.value.split(',').map((jobName, eachJobIndex) => {
              const job = cell.row.original.data[eachJobIndex]
              const boxFolderId = job.sharedBoxFolderId
              const bgColor = getBgColor(job.jobTypeColor, job.stage?.color)
              return (
                <div
                  className="job-hover"
                  key={eachJobIndex}
                  onClick={() => {
                    setBoxFolderId(boxFolderId)
                    setBoxFolderDescription(`${job.name} Files`)
                  }}
                  style={{ background: bgColor }}
                >
                  {
                    <span
                      key={index}
                      style={{ fontSize: '16px' }}
                      className="btn-link"
                    >
                      <FileEarmarkText /> View
                    </span>
                  }
                </div>
              )
            })}
          </td>
        )
      } else {
        const job = cell.row.original.data[0]
        const bgColor = getBgColor(job.jobTypeColor, job.stage?.color)
        const boxFolderId = job.sharedBoxFolderId
        return (
          <td
            className="job-hover"
            key={index}
            style={{ background: bgColor, width: '150px' }}
            {...cell.getCellProps()}
            onClick={() => {
              setBoxFolderId(boxFolderId)
              setBoxFolderDescription(`${job.name} Files`)
            }}
          >
            {
              <span
                key={index}
                style={{ fontSize: '16px' }}
                className="btn-link"
              >
                <FileEarmarkText /> View
              </span>
            }
          </td>
        )
      }
    } else if (cell.column.id === 'Name') {
      if (cell.value.indexOf(',') > -1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '150px' }}>
            {cell.value.split(',').map((eachJobName, eachJobIndex) => {
              const jobId = cell.row.original.data[eachJobIndex].id
              const bgColor = getBgColor(
                cell.row.original.data[eachJobIndex].jobTypeColor,
                cell.row.original.data[eachJobIndex].stage?.color
              )
              return (
                <div
                  className="job-hover"
                  key={eachJobIndex}
                  onClick={() => handleJobDetailClick(jobId)}
                  style={{ background: bgColor }}
                >
                  <small key={eachJobIndex}>
                    <span
                      className={eachJobIndex == 0 ? '' : ''}
                      key={eachJobIndex}
                    >
                      {eachJobName}
                    </span>
                  </small>
                </div>
              )
            })}
          </td>
        )
      } else {
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        return (
          <td
            className="job-hover"
            key={index}
            style={{ background: bgColor, width: '150px' }}
            {...cell.getCellProps()}
            onClick={() => handleJobDetailClick(cell.row.original.data[0].id)}
          >
            {
              <small>
                <span key={index}>{cell.value}</span>
              </small>
            }
          </td>
        )
      }
    } else if (cell.column.id === 'Stage') {
      if (cell.value.length > 1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '130px' }}>
            {cell.value.map((eachStageName, eachJobIndex) => {
              const jobId = cell.row.original.data[eachJobIndex].id
              const bgColor = cell.row.original.data[eachJobIndex].stage?.color
                ? cell.row.original.data[eachJobIndex].stage?.color
                : cell.row.original.data[eachJobIndex].jobTypeColor
              return (
                <div
                  className="job-hover"
                  key={eachJobIndex}
                  onClick={() => handleJobDetailClick(jobId)}
                  style={{ background: bgColor }}
                >
                  <small key={eachJobIndex}>
                    <span
                      className={eachJobIndex == 0 ? '' : ''}
                      key={eachJobIndex}
                    >
                      {eachStageName}
                    </span>
                  </small>
                </div>
              )
            })}
          </td>
        )
      } else {
        const bgColor = cell.row.original.data[0].stage?.color
          ? cell.row.original.data[0].stage?.color
          : cell.row.original.data[0].jobTypeColor
        return (
          <td
            className="job-hover"
            key={index}
            style={{ background: bgColor, width: '150px' }}
            {...cell.getCellProps()}
            onClick={() => handleJobDetailClick(cell.row.original.data[0].id)}
          >
            {
              <small>
                <span key={index}>{cell.value}</span>
              </small>
            }
          </td>
        )
      }
    } else if (cell.column.id === 'Bookable') {
      if (cell.value.indexOf(',') > -1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '130px' }}>
            {cell.value.split(',').map((eachJobBookable, eachJobIndex) => {
              const split = eachJobBookable.split('-')
              let bookables
              if (split[0] !== ' ') {
                bookables = split[0]
              }
              let sgs
              if (split[1] !== ' \n') {
                sgs = split[1]
              }
              const jobId = cell.row.original.data[eachJobIndex].id
              const bgColor = getBgColor(
                cell.row.original.data[eachJobIndex].jobTypeColor,
                cell.row.original.data[eachJobIndex].stage?.color
              )
              return (
                <OverlayTrigger
                  className={
                    cell.row.original.data[eachJobIndex].subjectGroups
                      .length === 0 &&
                    cell.row.original.data[eachJobIndex].packageCategories
                      .length === 0
                      ? 'd-none'
                      : ''
                  }
                  key="left"
                  placement="left"
                  overlay={
                    <Tooltip
                      className={
                        cell.row.original.data[eachJobIndex].subjectGroups
                          .length === 0 &&
                        cell.row.original.data[eachJobIndex].packageCategories
                          .length === 0
                          ? 'd-none'
                          : 'tooltip-inner mw-100'
                      }
                    >
                      <div className="text-start">
                        {cell.row.original.data[eachJobIndex].subjectGroups.map(
                          (sg, index) => (
                            <small key={index} className="d-block">
                              <Dot />
                              {sg.node.name}
                            </small>
                          )
                        )}
                        {cell.row.original.data[
                          eachJobIndex
                        ].packageCategories.map((pc, index) => (
                          <>
                            <small key={index} className="d-block">
                              <Dot />
                              {pc.node.name}
                            </small>
                          </>
                        ))}
                      </div>
                    </Tooltip>
                  }
                >
                  <div
                    style={{ background: bgColor }}
                    className="job-hover"
                    key={eachJobIndex}
                    onClick={() => handleJobDetailClick(jobId)}
                  >
                    <small key={eachJobIndex}>
                      {bookables && (
                        <>
                          <span>{bookables}</span>
                        </>
                      )}
                      {sgs && (
                        <>
                          {' '}
                          <span>{sgs}</span>
                        </>
                      )}
                    </small>
                  </div>
                </OverlayTrigger>
              )
            })}
          </td>
        )
      } else {
        const split = cell.value.split('-')
        let bookables
        if (split[0] !== ' ') {
          bookables = split[0]
        }
        let sgs
        if (split[1] !== ' \n') {
          sgs = split[1]
        }
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        return (
          <td
            style={{ background: bgColor, width: '130px' }}
            className="job-hover"
            key={index}
            {...cell.getCellProps()}
            onClick={() => handleJobDetailClick(cell.row.original.data[0].id)}
          >
            {
              <small>
                {bookables && (
                  <>
                    <span>{bookables}</span>
                  </>
                )}
                {sgs && (
                  <>
                    {' '}
                    <span>{sgs}</span>
                  </>
                )}
              </small>
            }
          </td>
        )
      }
    } else if (cell.column.id === 'Location') {
      if (cell.value.indexOf('|') > -1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '150px' }}>
            {cell.value.split('|').map((eachJobLocation, eachJobIndex) => {
              const jobId = cell.row.original.data[eachJobIndex].id
              const bgColor = getBgColor(
                cell.row.original.data[eachJobIndex].jobTypeColor,
                cell.row.original.data[eachJobIndex].stage?.color
              )
              return (
                <OverlayTrigger
                  key="left"
                  placement="left"
                  overlay={
                    <Tooltip style={{ width: '200px' }}>
                      {cell.row.original.data[eachJobIndex].locationFullAddress}
                    </Tooltip>
                  }
                >
                  <div
                    style={{ background: bgColor }}
                    className="job-hover"
                    key={eachJobIndex}
                    onClick={() => handleJobDetailClick(jobId)}
                  >
                    <small key={eachJobIndex}>
                      <span
                        className={eachJobIndex === 0 ? '' : ''}
                        key={eachJobIndex}
                      >
                        {eachJobLocation}
                      </span>
                    </small>
                  </div>
                </OverlayTrigger>
              )
            })}
          </td>
        )
      } else {
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        return (
          <OverlayTrigger
            key="left"
            placement="left"
            overlay={
              <Tooltip style={{ width: '200px' }}>
                {cell.row.original.data[0].locationFullAddress}
              </Tooltip>
            }
          >
            <td
              style={{ background: bgColor, width: '150px' }}
              className="job-hover"
              key={index}
              {...cell.getCellProps()}
              onClick={() => handleJobDetailClick(cell.row.original.data[0].id)}
            >
              {
                <small>
                  <span key={index}>{cell.value}</span>
                </small>
              }
            </td>
          </OverlayTrigger>
        )
      }
    } else if (cell.column.id === 'Sessions') {
      if (cell.value.indexOf(',') > -1) {
        return (
          <td key={index} {...cell.getCellProps()} style={{ width: '60px' }}>
            {cell.value.split(',').map((eachJobSession, eachJobIndex) => {
              const jobId = cell.row.original.data[eachJobIndex].id
              const bgColor = getBgColor(
                cell.row.original.data[eachJobIndex].jobTypeColor,
                cell.row.original.data[eachJobIndex].stage?.color
              )
              return (
                <div
                  style={{ background: bgColor }}
                  className="job-hover"
                  key={eachJobIndex}
                  onClick={() => {
                    if (contact) {
                      handleJobDetailClick(jobId)
                    } else {
                      handleSessionsClick(jobId)
                    }
                  }}
                >
                  <small key={eachJobIndex}>
                    <span
                      className={eachJobIndex === 0 ? '' : ''}
                      key={eachJobIndex}
                    >
                      {eachJobSession.toLocaleString()}
                    </span>
                  </small>
                </div>
              )
            })}
          </td>
        )
      } else {
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        return (
          <td
            style={{ background: bgColor, width: '60px' }}
            className="job-hover"
            key={index}
            {...cell.getCellProps()}
            onClick={() => {
              if (contact) {
                handleJobDetailClick(cell.row.original.data[0].id)
              } else {
                handleSessionsClick(cell.row.original.data[0].id)
              }
            }}
          >
            {
              <small>
                <span key={index}>{cell.value.toLocaleString()}</span>
              </small>
            }
          </td>
        )
      }
    } else if (cell.column.id === 'Booked') {
      let tdStyle = { width: '150px' }
      if (cell.row.original.data.length === 1) {
        const bgColor = getBgColor(
          cell.row.original.data[0].jobTypeColor,
          cell.row.original.data[0].stage?.color
        )
        tdStyle.background = bgColor
      }
      return (
        <td key={index} {...cell.getCellProps()} style={tdStyle}>
          {cell.row.original.data.map((eachJobUitl, index) => {
            const jobId = cell.row.original.data[index].id
            const bgColor = getBgColor(
              cell.row.original.data[index].jobTypeColor,
              cell.row.original.data[index].stage?.color
            )
            let variant
            if (eachJobUitl.utilization < 33) {
              variant = 'danger'
            } else if (eachJobUitl.utilization < 66) {
              variant = 'warning'
            } else if (eachJobUitl.utilization >= 66) {
              variant = 'success'
            }
            return (
              <div
                style={{ background: bgColor }}
                className="job-hover"
                id={`progress-${index}`}
                key={index}
                onClick={() => handleJobDetailClick(jobId)}
              >
                {eachJobUitl.bookable && (
                  <ProgressBar
                    animated
                    variant={variant}
                    style={{
                      height: '20px',
                      padding: cell.row.original.data.length > 1 && '0px',
                      overflow: cell.row.original.data.length > 1 && 'hidden',
                      display: cell.row.original.data.length > 1 && 'flex',
                      alignItems: cell.row.original.data.length > 1 && 'center',
                    }}
                    now={`${eachJobUitl.utilization}`}
                    label={`${eachJobUitl.utilization}%`}
                  />
                )}
              </div>
            )
          })}
        </td>
      )
    }
  }
  const constructTableRow = (row) => {
    prepareRow(row)
    return (
      <tr
        style={{ fontSize: '12px' }}
        className={row.original.data.length > 1 ? 'groupedRow' : ''}
        key={row.original.id}
        {...row.getRowProps()}
      >
        {row.cells.map((cell, index) => {
          return constructTableCell(cell, index)
        })}
      </tr>
    )
  }
  const handleJobDetailClick = (jobId) => {
    setJobDetailId(jobId)
    toggleJobDetailModal()
  }
  const toggleEmployeeAssignmentsModal = () => {
    if (!loggedInUser.permissions.isOrgContact) {
      if (showEmployeeAssignmentsModal) {
        setJobDetailId(null)
      }
      setShowEmployeeAssignmentsModal((prevState) => !prevState)
    }
  }
  const handleEmployeeDetailClick = (jobId) => {
    setJobDetailId(jobId)
    toggleEmployeeAssignmentsModal()
  }
  const handleSessionsClick = (jobId) => {
    if (!loggedInUser.permissions.isOrgContact) {
      setJobDetailId(jobId)
      setJobDetailOnlySessions(true)
      toggleJobDetailModal()
    }
  }
  const handleAddWeek = () => {
    if (startDateFilter) {
      const firstday = moment(startDateFilter.toJSDate())
        .add(1, 'weeks')
        .startOf('week')
        .format()
      const firstDayDate = new Date(firstday)
      var lastday = moment(startDateFilter.toJSDate())
        .add(1, 'weeks')
        .endOf('week')
        .format()
      const lastDayDate = new Date(lastday)
      setPrevNextStartDateFilter(firstDayDate)
      setPrevNextEndDateFilter(lastDayDate)
      setPrevNextDateClick(true)
    }
  }
  const handleSubtractWeek = () => {
    if (startDateFilter) {
      const firstday = moment(startDateFilter.toJSDate())
        .subtract(1, 'weeks')
        .startOf('week')
        .format()
      const firstDayDate = new Date(firstday)
      var lastday = moment(startDateFilter.toJSDate())
        .subtract(1, 'weeks')
        .endOf('week')
        .format()
      const lastDayDate = new Date(lastday)
      setPrevNextStartDateFilter(firstDayDate)
      setPrevNextEndDateFilter(lastDayDate)
      setPrevNextDateClick(true)
    }
  }

  if (!settings)
    return (
      <Row className="mt-4">
        <Col>
          <Loading message="Loading Schedule..." />
        </Col>
      </Row>
    )

  return (
    <>
      <FilesModal
        showModal={boxFolderId}
        toggleModal={() => {
          setBoxFolderId()
          setBoxFolderDescription()
        }}
        header={boxFolderDescrition}
        boxFolderId={boxFolderId}
      />
      <div className="jobsList">
        {showEmployeeAssignmentsModal && (
          <EmployeeAssignmentsModal
            setSchedulePollingInterval={setPollInterval}
            showModal={showEmployeeAssignmentsModal}
            toggleModal={toggleEmployeeAssignmentsModal}
            jobId={jobDetailId}
          />
        )}
        <Row>
          <div className="rbc-btn-group rbc-toolbar mt-4 small">
            <button type="button" onClick={handleSubtractWeek}>
              Previous Week
            </button>
            <button type="button" onClick={handleAddWeek}>
              Next Week
            </button>
          </div>
        </Row>
        <Row>
          <Col>
            <div
              id="infinite-wrapper"
              style={{ overflowY: 'auto', maxHeight: '1000px' }}
            >
              <InfiniteScroll
                dataLength={jobs.length}
                next={fetchMoreJobs}
                hasMore={hasMoreJobs}
                scrollableTarget="infinite-wrapper"
                height={tableHeight ? tableHeight : 800}
                loader={jobsQueryData ? <Loading /> : <></>}
              >
                <Table
                  className="table-bordered"
                  responsive
                  {...getTableProps()}
                  size="sm"
                >
                  <thead
                    style={{
                      backgroundColor: settings.colorScheme,
                    }}
                  >
                    {headerGroups.map((headerGroup, index) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => {
                          return (
                            <th
                              key={index}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              style={{
                                maxWidth: index === 0 ? '15%' : 'none',
                              }}
                            >
                              <p className="mb-0">{column.render('Header')}</p>
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDown className="ml-2" />
                                  ) : (
                                    <ArrowUp className="ml-2" />
                                  )
                                ) : (
                                  ''
                                )}
                              </span>
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {!jobsQueryData && (
                      <tr style={{ border: 'none' }}>
                        <td
                          colSpan={tableColumns.length}
                          style={{
                            textAlign: 'center',
                            padding: '20px',
                            border: 'none',
                          }}
                        >
                          <Loading />
                        </td>
                      </tr>
                    )}
                    {jobsQueryData && (
                      <>
                        {rows.map((row, index) => {
                          return constructTableRow(row)
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default JobsList
