import React, { useState, useEffect } from 'react'
import { produce } from 'immer'
import { Row, Col, Modal, Table, Form, Button } from 'react-bootstrap'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import { PlusCircle, Trash, SlashCircle } from 'react-bootstrap-icons'
import { COLORS } from '../../../libs/constant'
import toast from 'react-hot-toast'
import { ChromePicker } from 'react-color'
import DeleteJobTypeModal from './DeleteJobTypeModal'

const JobTypeModal = (props) => {
  const { showJobTypeModal, jobTypeModalShowChange } = props
  const [showNewJob, setShowNewJob] = useState(false)
  const [jobTypeFieldValue, setJobTypeFieldValue] = useState('')
  const [jobTypeFieldError, setJobTypeFieldError] = useState('')
  const [jobTypeData, setJobTypeData] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [jobTypeId, setJobTypeId] = useState()
  const [deleteJobType, setDeleteJobType] = useState()
  const [availableColors, setAvailableColors] = useState([])
  const [jobTypeColorFieldValue, setJobTypeColorFieldValue] = useState('')

  const { data: jobTypeQueryData } = useQuery(
    gql`
      query JobTypes {
        jobTypes(orderBy: "name") {
          edges {
            node {
              id
              name
              color
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [jobTypeNameCheck, { data: jobTypeNameCheckData }] = useLazyQuery(
    gql`
      query JobTypeNameCheck($jobTypesNameIexact: String) {
        jobTypes(name_Iexact: $jobTypesNameIexact) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [createJobType] = useMutation(
    gql`
      mutation CreateJobTypeMutations(
        $createJobTypeInput: CreateJobTypeInput!
      ) {
        createJobType(input: $createJobTypeInput) {
          jobType {
            id
            name
            color
          }
        }
      }
    `,
    {
      refetchQueries: ['JobTypes'],
      onCompleted: () => {
        setJobTypeColorFieldValue('')
        setJobTypeFieldValue('')
        setShowNewJob(false)
        toast.success('Job Type Saved')
      },
    }
  )

  const [updateJobType] = useMutation(
    gql`
      mutation UpdateJobType($updateJobTypeInput: UpdateJobTypeInput!) {
        updateJobType(input: $updateJobTypeInput) {
          jobType {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setJobTypeColorFieldValue('')
        setJobTypeFieldValue('')
        setShowNewJob(false)
        toast.success('Job Type Saved')
      },
      errorPolicy: 'all',
      refetchQueries: ['JobTypes'],
    }
  )

  useEffect(() => {
    const data = jobTypeQueryData?.jobTypes?.edges ?? []
    const transformedData = data.map((jType) => ({
      id: jType.node.id,
      name: jType.node.name,
      color:
        jType.node.color.charAt(0).toUpperCase() + jType.node.color.slice(1),
      originalName: jType.node.name,
      originalColor:
        jType.node.color.charAt(0).toUpperCase() + jType.node.color.slice(1),
      error: '',
    }))
    setJobTypeData(transformedData)

    const colors = COLORS.map((c) => c.charAt(0).toUpperCase() + c.slice(1))
    const takenColors = data.map((jType) => jType.node.color.toLowerCase())
    const availableColors = colors.filter(
      (color) => !takenColors.includes(color.toLowerCase())
    )
    setAvailableColors(availableColors)
  }, [jobTypeQueryData])

  const handleJobTypeNameChange = (e) => {
    const value = e.target.value
    setJobTypeFieldValue(value)

    if (value) {
      jobTypeNameCheck({
        variables: {
          jobTypesNameIexact: e.target.value,
        },
        onCompleted: (data) => {
          if (data.jobTypes.edges.length > 0) {
            setJobTypeFieldError('Name must be unique')
          } else {
            setJobTypeFieldError('')
          }
        },
      })
    }
  }

  const handleSubmit = () => {
    if (
      !(
        jobTypeNameCheckData && jobTypeNameCheckData.jobTypes.edges.length > 0
      ) &&
      jobTypeFieldValue !== ''
    ) {
      if (isEdit) {
        updateJobType({
          variables: {
            updateJobTypeInput: {
              jobTypeInput: {
                id: jobTypeId,
                name: jobTypeFieldValue,
                color: jobTypeColorFieldValue,
              },
            },
          },
        })
      } else {
        createJobType({
          variables: {
            createJobTypeInput: {
              jobTypeInput: {
                name: jobTypeFieldValue,
                color: jobTypeColorFieldValue,
              },
            },
          },
        })
      }
    } else {
      toast.error('Fields Required!')
    }
  }

  const handleSaveClick = (jobType) => {
    setJobTypeFieldValue(jobType.name)
    setJobTypeColorFieldValue(jobType.originalColor)
    setJobTypeId(jobType.id)
    setIsEdit(true)
    setShowNewJob(true)
  }

  const onHide = () => {
    setJobTypeFieldValue('')
    setShowNewJob(false)
    jobTypeModalShowChange()
  }

  if (!jobTypeData) {
    return (
      <Modal size="lg" show={showJobTypeModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Job Types</Modal.Title>
        </Modal.Header>
        <Modal.Body />
      </Modal>
    )
  }
  return (
    <>
      <DeleteJobTypeModal
        showModal={deleteJobType}
        toggleModal={setDeleteJobType}
        jobType={deleteJobType}
      />
      <Modal size="lg" show={showJobTypeModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title closeButton>Job Types</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {!showNewJob && (
                <Button
                  variant="link"
                  className="mb-2 pl-0"
                  onClick={() => {
                    setIsEdit(false)
                    setShowNewJob(true)
                    setJobTypeColorFieldValue(availableColors[0])
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Job Type
                </Button>
              )}
              <Form className={showNewJob ? 'mb-3' : 'd-none'}>
                <Row className="justify-content-center">
                  <Col md={4}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      size="sm"
                      placeholder="Name"
                      value={jobTypeFieldValue}
                      onChange={(e) => handleJobTypeNameChange(e)}
                    />
                    {jobTypeFieldError && (
                      <small className="text-danger">{jobTypeFieldError}</small>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Col md={4}>
                    <Form.Label>Color</Form.Label>
                    <ChromePicker
                      onChange={(e) => {
                        setJobTypeColorFieldValue(e.hex)
                      }}
                      color={jobTypeColorFieldValue}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col md={4} className="d-flex justify-content-around">
                    <Button
                      variant="link"
                      size="sm"
                      className="mt-n1"
                      onClick={handleSubmit}
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                    <Button
                      variant="link"
                      size="sm"
                      className="mt-n1"
                      onClick={() => {
                        setShowNewJob(false)
                        setJobTypeFieldValue('')
                        setJobTypeColorFieldValue('')
                        setIsEdit(false)
                      }}
                    >
                      <SlashCircle className="mr-2" />
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {jobTypeData.map((jType, index) => (
                    <tr key={jType.id}>
                      <td>{jType.name}</td>
                      <td>
                        <div style={{ color: jType.originalColor }}>
                          {jType.originalColor}
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          size="sm"
                          className="ps-0 mt-n1"
                          onClick={() => handleSaveClick(jType)}
                        >
                          <PlusCircle className="mr-2" />
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => setDeleteJobType(jType)}
                          variant="link"
                          size="sm"
                          className="ps-0 mt-n1"
                        >
                          <Trash /> Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default JobTypeModal
