import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal, Image } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { PlusCircle } from 'react-bootstrap-icons'
import 'react-phone-input-2/lib/style.css'
import validator from 'validator'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, gql, useLazyQuery } from '@apollo/client'
import { FilePond } from 'react-filepond'
import { TIMEZONES, LOCATION_FORMATS } from '../../../libs/constant'
import { formatTimezone, hexToRgb, rgbToHex } from '../../../libs/utils'
import Loading from '../../common/Loading'
import { timezoneVar, settingsVar } from '../../../libs/apollo'
import toast from 'react-hot-toast'
import { ChromePicker } from 'react-color'

const EditBusinessInfoModal = (props) => {
  const { settings, showModal, toggleModal, logoImage } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [rbg, setRgb] = useState(hexToRgb(settings.colorScheme))

  const [getSettings] = useLazyQuery(
    gql`
      query PublicSettingsQuery {
        publicSettings {
          id
          name
          tenantId
          airstudioInvoiceRecipient
          chargeProductStateSalesTax
          productTaxRate
          displayLogoInNav
          displayNameInNav
          websiteUrl
          phoneNumber
          openAiEnabled
          thumbnailImageName
          timezone
          locationFormat
          showLocationBooking
          intercomAppId
          showLastNameIdLogin
          tenantActive
          chargeStateSalesTax
          email
          openAiEnabled
          colorScheme
          facebookUrl
          showSubjectGroupBookingLogin
          subjectGroupBookingLogin
          tenantSubscription
          twitterUrl
          linkedinUrl
          instagramUrl
          instagramInFooter
          facebookInFooter
          twitterInFooter
          anonymousLogin
          linkedinInFooter
          sessionStages
          jobStages
          requireSubjectEmail
          requireSubjectPhone
          googleCalendarConnectorEnabled
          googleCalendarEnabledForSubjectSessions
          googleCalendarEnabledForEmployeeJobs
          googleCalendarEnabledForOrganizationSessions
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        settingsVar(data.publicSettings)
      },
    }
  )

  const [updateSettingsMutation] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        getSettings()
        setIsSubmitting(false)
        toast.success('Studio Details Updated')
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['PublicSettingsQuery', 'SettingsQuery'],
    }
  )
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      website: '',
      timezone: '',
      showLastNameIdLogin: true,
      productTaxRate: 0,
      locationFormat: '',
      showLocationBooking: true,
      logoImage: '',
      displayLogoInNav: '',
      jobBookingEndHours: 0,
      showSubjectGroupBookingLogin: true,
      subjectGroupBookingLogin: '',
      displayNameInNav: '',
      linkedinUrl: '',
      facebookUrl: '',
      autoCompleteSessions: true,
      autoCompleteJobs: true,
      instagramUrl: '',
      jobBookingEndsEndOfJob: true,
      twitterUrl: '',
      anonymousLogin: false,
      linkedinInFooter: null,
      facebookInFooter: null,
      twitterInFooter: null,
      instagramInFooter: null,
      sessionStages: false,
      jobStages: false,
      requireSubjectEmail: false,
      requireSubjectPhone: false,
    },
    validationSchema: Yup.object().shape({
      locationFormat: Yup.string().required('Location format is required'),
      showLocationBooking: Yup.bool().required(
        'Show location booking is required'
      ),
      showSubjectGroupBookingLogin: Yup.bool().required(
        'Show subject group booking on login is required'
      ),
      subjectGroupBookingLogin: Yup.string().nullable(),
      name: Yup.string()
        .nullable()
        .min(2, 'Name is too short')
        .max(100, 'Name is too ling')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      primaryContactEmail: Yup.string()
        .nullable()
        .min(2, 'Email is too short')
        .max(100, 'Email is too ling')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      primaryContactName: Yup.string()
        .nullable()
        .min(2, 'Name is too short')
        .max(100, 'Name is too ling')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      phoneNumber: Yup.string()
        .nullable()
        .min(5, 'Phone number one is too short')
        .max(100, 'Phone number one is too long')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      website: Yup.string()
        .nullable()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      linkedinUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      facebookUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      instagramUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      twitterUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      productTaxRate: Yup.number()
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (isNaN(value)) {
            valid = false
          }
          return valid
        })
        .min(0, 'Must be between 0 and 100')
        .max(100, 'Must be between 0 and 100'),
      autoCompleteJobs: Yup.bool().nullable(),
      autoCompleteSessions: Yup.bool().nullable(),
      jobBookingEndHours: Yup.number().required('Required'),
      linkedinInFooter: Yup.bool().nullable(),
      showLastNameIdLogin: Yup.bool().nullable(),
      anonymousLogin: Yup.bool().nullable(),
      facebookInFooter: Yup.bool().nullable(),
      twitterInFooter: Yup.bool().nullable(),
      jobBookingEndsEndOfJob: Yup.bool().nullable(),
      chargeStateSalesTax: Yup.bool().nullable(),
      chargeProductStateSalesTax: Yup.bool().nullable(),
      instagramInFooter: Yup.bool().nullable(),
      sessionStages: Yup.bool().nullable(),
      jobStages: Yup.bool().nullable(),
      requireSubjectEmail: Yup.bool().nullable(),
      requireSubjectPhone: Yup.bool().nullable(),
      timezone: Yup.string()
        .min(3, 'Timezone is too short')
        .max(100, 'Timezone is too long')
        .required('Timezone is required')
        .test('isTimezone', 'Invalid timezone', (value) => {
          let valid = true
          if (value && !TIMEZONES.includes(value)) {
            valid = false
          }
          return valid
        }),
      email: Yup.string()
        .min(3, 'Email is too short')
        .max(100, 'Email is too long')
        .required('Email is required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      displayLogoInNav: Yup.bool().required(
        'Navigation includes logo is required'
      ),
      displayNameInNav: Yup.bool().required(
        'Navigation includes logo is required'
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      let newLogoImage
      if (values.logoImage) {
        newLogoImage = {
          image: values.logoImage[0].file,
          nameOnUpload: values.logoImage[0].filename,
        }
      }
      if (values.timezone === 'US/PACIFIC') {
        timezoneVar('America/LOS_ANGELES')
      } else if (values.timezone === 'US/CENTRAL') {
        timezoneVar('America/Chicago')
      } else if (values.timezone === 'US/EASTERN') {
        timezoneVar('America/New_York')
      } else if (values.timezone === 'US/ALASKA') {
        timezoneVar('America/Juneau')
      } else if (values.timezone === 'US/MOUNTAIN') {
        timezoneVar('America/Denver')
      } else {
        timezoneVar('Pacific/Honolulu')
      }
      let colorScheme
      if (rbg?.r) {
        colorScheme = rgbToHex(rbg.r, rbg.g, rbg.b)
      }
      updateSettingsMutation({
        variables: {
          updateSettingsInput: {
            settingsInput: {
              id: settings.id,
              name: values.name,
              productTaxRate: values.productTaxRate,
              phoneNumber: values.phoneNumber,
              website: values.website,
              showLastNameIdLogin: values.showLastNameIdLogin,
              timezone: `${values.timezone}`,
              email: values.email,
              logoImage: newLogoImage,
              locationFormat: values.locationFormat,
              showLocationBooking: values.showLocationBooking,
              anonymousLogin: values.anonymousLogin,
              jobBookingEndsEndOfJob: values.jobBookingEndsEndOfJob,
              linkedinUrl: values.linkedinUrl,
              chargeProductStateSalesTax: values.chargeProductStateSalesTax,
              facebookUrl: values.facebookUrl,
              autoCompleteSessions: values.autoCompleteSessions,
              autoCompleteJobs: values.autoCompleteJobs,
              instagramUrl: values.instagramUrl,
              twitterUrl: values.twitterUrl,
              showSubjectGroupBookingLogin: values.showSubjectGroupBookingLogin,
              subjectGroupBookingLogin: values.subjectGroupBookingLogin,
              jobBookingEndHours: values.jobBookingEndHours,
              linkedinInFooter: values.linkedinInFooter,
              facebookInFooter: values.facebookInFooter,
              chargeStateSalesTax: values.chargeStateSalesTax,
              twitterInFooter: values.twitterInFooter,
              instagramInFooter: values.instagramInFooter,
              displayLogoInNav: values.displayLogoInNav,
              displayNameInNav: values.displayNameInNav,
              primaryContactName: values.primaryContactName,
              primaryContactEmail: values.primaryContactEmail,
              sessionStages: values.sessionStages,
              jobStages: values.jobStages,
              requireSubjectEmail: values.requireSubjectEmail,
              requireSubjectPhone: values.requireSubjectPhone,
              colorScheme,
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (showModal && settings) {
      formik.setValues({
        name: settings.name,
        email: settings.email,
        productTaxRate: settings.productTaxRate,
        phoneNumber: settings.phoneNumber,
        website: settings.website,
        showLastNameIdLogin: settings.showLastNameIdLogin,
        showSubjectGroupBookingLogin: settings.showSubjectGroupBookingLogin,
        subjectGroupBookingLogin: settings.subjectGroupBookingLogin,
        timezone: formatTimezone(settings.timezone),
        displayLogoInNav: settings.displayLogoInNav,
        displayNameInNav: settings.displayNameInNav,
        linkedinUrl: settings.linkedinUrl,
        locationFormat: settings.locationFormat,
        showLocationBooking: settings.showLocationBooking,
        jobBookingEndsEndOfJob: settings.jobBookingEndsEndOfJob,
        autoCompleteJobs: settings.autoCompleteJobs,
        autoCompleteSessions: settings.autoCompleteSessions,
        chargeProductStateSalesTax: settings.chargeProductStateSalesTax,
        chargeStateSalesTax: settings.chargeStateSalesTax,
        facebookUrl: settings.facebookUrl,
        anonymousLogin: settings.anonymousLogin,
        jobBookingEndHours: settings.jobBookingEndHours,
        instagramUrl: settings.instagramUrl,
        twitterUrl: settings.twitterUrl,
        linkedinInFooter: settings.linkedinInFooter,
        facebookInFooter: settings.facebookInFooter,
        twitterInFooter: settings.twitterInFooter,
        instagramInFooter: settings.instagramInFooter,
        primaryContactEmail: settings.primaryContactEmail,
        primaryContactName: settings.primaryContactName,
        sessionStages: settings.sessionStages,
        jobStages: settings.jobStages,
        requireSubjectEmail: settings.requireSubjectEmail,
        requireSubjectPhone: settings.requireSubjectPhone,
      })
    }
  }, [showModal, settings])

  const handleFileUpload = (updateLogoImage) => {
    formik.setFieldValue('logoImage', updateLogoImage)
  }

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
    setRgb()
  }

  if (showModal) {
    return (
      <>
        <div className="editBusinessModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={innerToggleModal}
            aria-labelledby="new-title"
          >
            <Form
              onSubmit={formik.handleSubmit}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Studio Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Name
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Email
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Primary Contact Name
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="primaryContactName"
                      value={formik.values.primaryContactName}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.primaryContactName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.primaryContactName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Primary Contact Email
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="primaryContactEmail"
                      value={formik.values.primaryContactEmail}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.primaryContactEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.primaryContactEmail}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Website
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.website}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.website}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Show Subject Group Booking Login
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="showSubjectGroupBookingLogin"
                        type="checkbox"
                        checked={formik.values.showSubjectGroupBookingLogin}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `showSubjectGroupBookingLogin`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {formik.values.showSubjectGroupBookingLogin && (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Subject Group Booking Login
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="subjectGroupBookingLogin"
                        value={formik.values.subjectGroupBookingLogin}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.subjectGroupBookingLogin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.subjectGroupBookingLogin}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Job Booking Ends{' '}
                      {formik.values.jobBookingEndsEndOfJob ? (
                        <>When Job Ends</>
                      ) : (
                        <>
                          {formik.values.jobBookingEndHours} Hours Before Job
                          Start
                        </>
                      )}
                    </Form.Label>
                    <Col md={3}>
                      <Form.Check
                        name="jobBookingEndsEndOfJob"
                        type="checkbox"
                        label="When Job Ends"
                        checked={formik.values.jobBookingEndsEndOfJob}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `jobBookingEndsEndOfJob`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>

                    {!formik.values.jobBookingEndsEndOfJob && (
                      <Col md={3}>
                        <Form.Control
                          type="number"
                          min={0}
                          name="jobBookingEndHours"
                          value={formik.values.jobBookingEndHours}
                          onChange={formik?.handleChange}
                          isInvalid={formik?.errors?.jobBookingEndHours}
                        />
                      </Col>
                    )}
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Anonymous Login
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="autoCompleteJobs"
                        type="checkbox"
                        checked={formik.values.anonymousLogin}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `anonymousLogin`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Auto Complete Jobs
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="autoCompleteJobs"
                        type="checkbox"
                        checked={formik.values.autoCompleteJobs}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `autoCompleteJobs`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Auto Complete Sessions
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="autoCompleteSessions"
                        type="checkbox"
                        checked={formik.values.autoCompleteSessions}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `autoCompleteSessions`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Show Last Name ID Login
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="showLastNameIdLogin"
                        type="checkbox"
                        checked={formik.values.showLastNameIdLogin}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `showLastNameIdLogin`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Require Location During Booking
                    </Form.Label>
                    <Col>
                      <Form.Check
                        name="showLocationBooking"
                        type="checkbox"
                        checked={formik.values.showLocationBooking}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `showLocationBooking`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Charge Sessions Sales Tax
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="chargeStateSalesTax"
                        type="checkbox"
                        checked={formik.values.chargeStateSalesTax}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `chargeStateSalesTax`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Default Charge Product Sales Tax
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="chargeProductStateSalesTax"
                        type="checkbox"
                        checked={formik.values.chargeProductStateSalesTax}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `chargeProductStateSalesTax`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Display Session Stages
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="sessionStages"
                        type="checkbox"
                        checked={formik.values.sessionStages}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `sessionStages`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Display Job Stages
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="jobStages"
                        type="checkbox"
                        checked={formik.values.jobStages}
                        onChange={(e) => {
                          formik.setFieldValue(`jobStages`, e.target.checked)
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Require Subject Email Booking
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="requireSubjectEmail"
                        type="checkbox"
                        checked={formik.values.requireSubjectEmail}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `requireSubjectEmail`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Require Subject Phone Bookin
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        name="requireSubjectPhone"
                        type="checkbox"
                        checked={formik.values.requireSubjectPhone}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `requireSubjectPhone`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {formik.values.chargeProductStateSalesTax && (
                  <Form.Group>
                    <Row className="mt-2">
                      <Form.Label column sm="3">
                        Default Product Sales Tax Percent
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          name="productTaxRate"
                          value={formik.values.productTaxRate}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.productTaxRate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.productTaxRate}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                )}
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Facebook URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="facebookUrl"
                        value={formik.values.facebookUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.facebookUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.facebookUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="facebookInFooter"
                        label="Facebook in Footer"
                        type="checkbox"
                        checked={formik.values.facebookInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `facebookInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Instagram URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="instagramUrl"
                        value={formik.values.instagramUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.instagramUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.instagramUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="instagramInFooter"
                        label="Instagram in Footer"
                        type="checkbox"
                        checked={formik.values.instagramInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `instagramInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      LinkedIn URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="linkedinUrl"
                        value={formik.values.linkedinUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.linkedinUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.linkedinUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="linkedinInFooter"
                        label="LinkedIn in Footer"
                        type="checkbox"
                        checked={formik.values.linkedinInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `linkedinInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Twitter URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="twitterUrl"
                        value={formik.values.twitterUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.twitterUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.twitterUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="twitterInFooter"
                        label="Twitter in Footer"
                        type="checkbox"
                        checked={formik.values.twitterInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `twitterInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Timezone
                  </Form.Label>
                  <Col md={4}>
                    <Form.Control
                      name="timezone"
                      as="select"
                      value={formik.values.timezone}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.timezone}
                    >
                      {TIMEZONES.map((timezone) => (
                        <option key={timezone} value={timezone}>
                          {timezone}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.timezone}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Location Format
                  </Form.Label>
                  <Col md={4}>
                    <Form.Control
                      name="locationFormat"
                      as="select"
                      value={formik.values.locationFormat}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.locationFormat}
                    >
                      {LOCATION_FORMATS.map((locationFormat) => (
                        <option key={locationFormat} value={locationFormat}>
                          {locationFormat}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.locationFormat}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3" className="mr-0 pr-0">
                    Phone Number
                  </Form.Label>
                  <Col md={8}>
                    <PhoneInput
                      country="us"
                      name="phoneNumber"
                      placeholder=""
                      regions={['north-america']}
                      value={formik.values.phoneNumber}
                      onChange={(phone, country) =>
                        formik.setFieldValue('phoneNumber', phone)
                      }
                    />
                    {formik.errors.phoneNumber ? (
                      <span className="text-danger">
                        {formik.errors.phoneNumber}
                      </span>
                    ) : null}
                  </Col>
                </Form.Group>
                <Row className="mb-2">
                  <Col xs={12} md={12}>
                    <Form.Label>Logo</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Image
                      fluid
                      src={`data:image/jpeg;base64,${logoImage}`}
                      alt="Studio logo"
                    />
                  </Col>
                </Row>
                <Form.Group>
                  <Row className="mt-4">
                    <Col xs={12}>
                      <Form.Label>New Logo</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 10, offset: 1 }}>
                      <FilePond
                        files={formik.values.logoImage}
                        onupdatefiles={handleFileUpload}
                        acceptedFileTypes={['image/png', 'image/jpeg']}
                        allowMultiple
                        allowFileSizeValidation
                        maxFileSize="15MB"
                        labelMaxFileSize="Maximum preview image size is {filesize}"
                        maxFiles={1}
                        name="files"
                        labelIdle='Drag and drop an image or <span class="filepond--label-action">Browse</span>'
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-4">
                    <Col xs={12}>
                      <Form.Label>Color Scheme</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 6, offset: 4 }}>
                      <ChromePicker
                        onChange={(e) => setRgb(e.rgb)}
                        color={rbg}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Col>
                      <Form.Check
                        name="displayNameInNav"
                        label="Navigation Includes Name"
                        type="checkbox"
                        checked={formik.values.displayNameInNav}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Col>
                      <Form.Check
                        name="displayLogoInNav"
                        label="Navigation Includes Logo"
                        type="checkbox"
                        checked={formik.values.displayLogoInNav}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md={3}>
                    <Button
                      type="submit"
                      block
                      variant="outline-primary"
                      disabled={isSubmitting}
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                </Row>

                {isSubmitting ? (
                  <Row className="mt-2">
                    <Col>
                      <Loading />
                    </Col>
                  </Row>
                ) : null}
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default EditBusinessInfoModal
