import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery, gql } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import JobPage from './job/Job'
import { useAWSS3 } from '../../libs/aws'
import toast from 'react-hot-toast'
import './JobDetailSessionsTable.css'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import FotomerchantGalleryPasswordModal from '../fotomerchant/FotomerchantGalleryPasswordModal'
import Loading from '../common/Loading'
import SessionDetailModal from '../subject_sessions/SessionDetailModal'
import SessionDetailFormModal from '../sessions/SessionDetailFormModal'
import { useDownloadFile } from '../../libs/downloadFile'
import NoShowModal from './NoShowModal'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const JobDetailSessionsTable = (props) => {
  const {
    jobId,
    sessionsOnly,
    singleSessionsOnly,
    sessionId,
    toggleModal,
    setSchedulePollingInterval,
  } = props
  const history = useHistory()
  const { contact } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [fotomerchantGalleryPassword, setFotomerchantGalleryPassword] =
    useState()
  const awsS3 = useAWSS3()
  const { downloadAndDeleteFile, downloadFile } = useDownloadFile()
  const canAddSessions = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [savingNoShow, setSavingNoShow] = useState(false)
  const [savingNotNoShow, setSavingNotNoShow] = useState(false)
  const [filterCancelledSessions, setFilterCancelledSessions] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [noShowSession, setNoShowSession] = useState()
  const [pollInterval, setPollInterval] = useState(null)
  const [loadingSessions, setLoadingSessions] = useState(true)
  const [removeCancelledSession, setRemoveCancelledSession] = useState()
  const [downloadingFile, setDownloadingFile] = useState()
  const [savingComplete, setSavingComplete] = useState(false)
  const [savingNotComplete, setSavingNotComplete] = useState(false)
  const [savingCancel, setSavingCancel] = useState(false)
  const [editRowNode, setEditRowNode] = useState(null)
  const [detailSessionId, setDetailSessionId] = useState(null)
  const [showSessionDetailModal, setShowSessionDetailModal] = useState(false)
  const [selectedSessionId, setSelectedSessionId] = useState(null)
  const orgContact = loggedInUser.permissions.isOrgContact
  const [downloadSessionsReport] = useMutation(
    gql`
      mutation DownloadSessions($input: DownloadSessionsInput!) {
        downloadSessions(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadSessions?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadSessions.file.fileName,
            data.downloadSessions.file.displayName,
            data.downloadSessions.file.id,
            () => {
              toast.success(`Session Ticket Downloaded`)
              setDownloadingFile(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const [updateSessionMutation] = useMutation(
    gql`
      mutation UpdateSession($updateSessionInput: UpdateSessionInput!) {
        updateSession(input: $updateSessionInput) {
          session {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        if (savingCancel) {
          toast.success('Session Cancelled')
          setSavingCancel(false)
        } else {
          if (savingNoShow) {
            setSavingNoShow(false)
          }
          if (savingComplete) {
            setSavingComplete(false)
          }
          if (savingNotComplete) {
            setSavingNotComplete(false)
          }
          if (savingNotNoShow) {
            setSavingNotNoShow(false)
          }
          toast.success('Session Saved')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['JobDetailSessionsQuery'],
    }
  )

  const tableColumns = [
    {
      Header: 'Id',
      id: 'id',
      accessor: 'recordId',
    },
    {
      Header: 'Time',
      accessor: 'time',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Session Package',
      accessor: 'sessionPackage',
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: (row) => row.subject,
      Cell: ({ value }) => {
        if (value) {
          return <div className="btn-link">{value}</div>
        }
        return null
      },
    },
    {
      Header: 'Subject Phone',
      accessor: 'subjectPhone',
    },
    {
      Header: 'Subject Email',
      accessor: 'subjectEmail',
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: (row) => row.subjectGroup,
      Cell: ({ row }) => {
        const subjectGroup = row.original.subjectGroup
        if (subjectGroup) {
          return (
            <div
              className="btn-link"
              onClick={() => {
                if (contact) {
                  history.push(
                    `/subject-group/${row.original.node?.subjectGroup.id}/contact`
                  )
                } else {
                  history.push(
                    `/subject-group/${row.original.node?.subjectGroup.id}`
                  )
                }
              }}
            >
              {subjectGroup}
            </div>
          )
        }
        return null
      },
    },
    {
      Header: 'Organization',
      accessor: (row) => {
        let organization = row.organization
        if (row.node?.organization?.accountManager) {
          organization = `${organization} | ${row.node?.organization?.accountManager.gaiaUser.fullName}`
        }
        if (organization) {
          return (
            <div
              className="btn-link"
              onClick={() => {
                if (contact) {
                  history.push(
                    `/organization/${row.node?.organization.id}/contact`
                  )
                } else {
                  history.push(`/organization/${row.node?.organization.id}`)
                }
              }}
            >
              {organization}
            </div>
          )
        }
      },
    },
    {
      Header: 'Resit Reason',
      accessor: 'resitReason',
    },
    {
      Header: '',
      id: 'actions',
      accessor: (row) => {
        const { node } = row
        return (
          <>
            {!node.cancelled && !node.rescheduled && (
              <>
                <div className="job-sessions-buttons">
                  {!node.noShow && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2"
                        onClick={() => {
                          setNoShowSession(node.id)
                        }}
                      >
                        No Show
                      </Button>
                    </>
                  )}
                  {node.noShow && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2"
                        onClick={() => {
                          setSavingNotNoShow(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: node.id,
                                  noShow: false,
                                },
                              },
                            },
                          })
                        }}
                      >
                        Not No Show
                      </Button>
                    </>
                  )}
                  <br />
                  {!node.completed && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2"
                        onClick={() => {
                          setSavingComplete(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: node.id,
                                  completed: true,
                                },
                              },
                            },
                          })
                        }}
                      >
                        Complete
                      </Button>
                    </>
                  )}
                  {node.completed && (
                    <>
                      <Button
                        type="submit"
                        variant="link"
                        className="mt-2 btn-sm"
                        onClick={() => {
                          setSavingNotComplete(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: node.id,
                                  completed: false,
                                },
                              },
                            },
                          })
                        }}
                      >
                        Not Complete
                      </Button>
                    </>
                  )}
                  <br />
                  <Button
                    type="submit"
                    variant="link"
                    className="mt-2"
                    disabled={downloadingFile}
                    onClick={() => {
                      setDownloadingFile(true)
                      downloadSessionsReport({
                        variables: {
                          input: {
                            sessionIds: [node.id],
                            jobId: null,
                            fileType: 'pdf',
                            file: 'sessions_sitting',
                          },
                        },
                      })
                    }}
                  >
                    Download Ticket
                  </Button>
                  <br />
                  <Button
                    type="submit"
                    variant="link"
                    className="mt-2"
                    onClick={() => {
                      setShowSessionDetailModal(true)
                      setSelectedSessionId(node.id)
                    }}
                  >
                    Employee Details
                  </Button>
                </div>
              </>
            )}
          </>
        )
      },
    },
  ]

  if (orgContact) {
    tableColumns.splice(tableColumns.length - 1, 1)
  }

  const [
    query,
    {
      loading: sessionsQueryLoading,
      error: sessionsQueryError,
      data: sessionsQueryData,
      fetchMore,
    },
  ] = useLazyQuery(
    gql`
      query JobDetailSessionsQuery(
        $sessionsJob: ID
        $cursor: String
        $contact: Boolean
        $cancelled: Boolean
      ) {
        sessions(
          first: 250
          after: $cursor
          contact: $contact
          job: $sessionsJob
          cancelled: $cancelled
          rescheduled: false
          sessionPackageChanged: false
          orderBy: "start_date_time"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              contentType {
                model
                id
              }
              recordId
              cancelled
              waiveBookingFee
              price
              completed
              noShow
              startDateTime
              endDateTime
              subjectGroup {
                id
                name
                organization {
                  id
                  name
                }
              }
              organizationPackageBackground {
                backgroundColor
              }
              stripePaymentIntents(orderBy: "-created", first: 1) {
                edges {
                  node {
                    id
                    stripeId
                    refunded
                    stripeRefundIntents(orderBy: "-created", first: 1) {
                      edges {
                        node {
                          refundedBySystem
                          refundedBy {
                            fullName
                          }
                        }
                      }
                    }
                  }
                }
              }
              organization {
                id
                name
                accountManager {
                  gaiaUser {
                    fullName
                  }
                }
              }
              subject {
                id
                gaiaUser {
                  firstName
                  lastName
                  email
                  fullName
                  secondaryEmail
                  phoneNumber
                  dummyUsername
                  secondaryPhoneNumber
                }
              }
              resitPreviousSession {
                id
                resitReason {
                  reason
                }
              }
              sessionPackage {
                id
                title
              }
              resitReason {
                reason
              }
              noShow
              cancelled
              completed
              resitScheduled
              sessionPackageChanged
              rescheduled
              billSubject
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      pollInterval,
      variables: {
        sessionsJob: jobId,
        contact: orgContact,
      },
      onCompleted: (data) => {
        data.sessions.edges.forEach((edge) => {
          const { node } = edge
          if (editRowNode && node.id === editRowNode.id) {
            setEditRowNode(node)
          }
        })
      },
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          sessionsJob: jobId,
          contact: orgContact,
          cancelled: filterCancelledSessions,
        },
      })
    }
  }, [initialQueryRun])

  const transformSessions = (sessions) => {
    return sessions.edges.map(({ node }) => {
      // Determine the session status
      let status
      if (node.noShow) {
        if (!node.resitScheduled || node.futuristSession?.cancelled) {
          status = 'No Show'
        } else {
          status = 'Resit Scheduled'
        }
      } else if (node.completed) {
        if (
          !node.resitScheduled ||
          (node.futuristSession && node.futuristSession?.cancelled)
        ) {
          status = 'Complete'
        } else {
          status = 'Resit Scheduled'
        }
      } else if (node.cancelled) {
        status = 'Canceled'
      } else if (node.sessionPackageChanged) {
        status = 'Session Package Changed'
      } else if (node.rescheduled && !node.futuristSession?.cancelled) {
        status = 'Rescheduled'
      } else {
        status = 'Upcoming'
      }

      // Format the start and end times
      const startDateTime = DateTime.fromISO(node.startDateTime)
      const endDateTime = DateTime.fromISO(node.endDateTime)

      // Retrieve subject information
      const gaiaUserNode = node.subject?.gaiaUser || {
        firstName: '',
        lastName: '',
      }

      // Combine phone numbers
      let phone = ''
      if (gaiaUserNode.phoneNumber) {
        phone = gaiaUserNode.phoneNumber
      }
      if (gaiaUserNode.secondaryPhoneNumber) {
        phone = phone
          ? `${phone}, ${gaiaUserNode.secondaryPhoneNumber}`
          : gaiaUserNode.secondaryPhoneNumber
      }

      // Combine email addresses
      let email = ''
      if (!gaiaUserNode.dummyUsername && gaiaUserNode.email) {
        email = gaiaUserNode.email
      }
      if (gaiaUserNode.secondaryEmail) {
        email = email
          ? `${email}, ${gaiaUserNode.secondaryEmail}`
          : gaiaUserNode.secondaryEmail
      }

      // Determine the payment information
      let paid
      if (node.waiveBookingFee) {
        paid = '$0'
      } else {
        paid = `$${node.price}`
      }
      if (node.stripePaymentIntents?.edges?.length > 0) {
        const paymentIntent = node.stripePaymentIntents.edges[0].node
        if (paymentIntent.stripeRefundIntents?.edges?.length > 0) {
          const refundIntent = paymentIntent.stripeRefundIntents.edges[0].node
          const refundType = paymentIntent.refunded
            ? 'refunded'
            : 'partially refunded'
          if (refundIntent.refundedBySystem) {
            paid = `${paid} ${refundType} by Airstudio`
          } else if (refundIntent.refundedBy) {
            paid = `${paid} ${refundType} by ${refundIntent.refundedBy?.fullName}`
          }
        }
      }

      // Construct the session package string
      let sessionPackage = node.sessionPackage?.title || ''
      if (node.organizationPackageBackground) {
        sessionPackage = `${sessionPackage} - ${node.organizationPackageBackground.backgroundColor}`
      }

      // Determine organization info
      let organizationName = ''
      let organizationId = ''
      if (node.subjectGroup?.organization) {
        organizationName = node.subjectGroup.organization.name
        organizationId = node.subjectGroup.organization.id
      } else if (node.organization) {
        organizationName = node.organization.name
        organizationId = node.organization.id
      }

      // Get the resit reason if available
      let resitReason
      if (
        node.resitPreviousSession &&
        node.resitPreviousSession.id !== node.id
      ) {
        resitReason = node.resitPreviousSession.resitReason?.reason
      }

      // Return the transformed session object
      return {
        status,
        cancelled: node.cancelled,
        time: `${startDateTime.toFormat('h:mma')} - ${endDateTime.toFormat('h:mma')}`,
        recordId: node.recordId,
        sessionPackage,
        subject: `${gaiaUserNode.firstName} ${gaiaUserNode.lastName}`,
        subjectGroup: node.subjectGroup?.name || '',
        organization: organizationName,
        subjectId: node.subject?.id,
        subjectGroupId: node.subjectGroup?.id,
        subjectEmail: email,
        subjectPhone: phone,
        organizationId,
        paid,
        resitReason,
        id: node.id,
        contentType: node.contentType,
        billSubject: node.billSubject ? 'Subject' : 'Organization',
        node,
      }
    })
  }

  const sessions = useMemo(() => {
    if (sessionsQueryData && sessionsQueryData.sessions) {
      return transformSessions(sessionsQueryData.sessions)
    }
    return []
  }, [sessionsQueryData])

  useEffect(() => {
    if (pollInterval === 500) {
      const timeout = setTimeout(() => {
        setPollInterval(null)
      }, 400)
      return () => clearTimeout(timeout)
    }
  }, [pollInterval])

  useEffect(() => {
    if (initialQueryRun) {
      setLoadingSessions(true)
      query({
        variables: {
          sessionsJob: jobId,
          contact: orgContact,
          cancelled: filterCancelledSessions,
        },
      })
    }
  }, [filterCancelledSessions, initialQueryRun])

  useEffect(() => {
    if (sessionsQueryData?.sessions?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          sessionsJob: jobId,
          contact: orgContact,
          cursor: sessionsQueryData.sessions.pageInfo.endCursor,
          cancelled: filterCancelledSessions,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            setLoadingSessions(false)
            return prev
          }
          return {
            sessions: {
              ...fetchMoreResult.sessions,
              edges: [
                ...prev.sessions.edges,
                ...fetchMoreResult.sessions.edges,
              ],
            },
          }
        },
      })
    } else {
      setLoadingSessions(false)
    }
  }, [sessionsQueryData])

  if (sessionsQueryError) return <>Error loading sessions</>
  return (
    <>
      {noShowSession && (
        <NoShowModal
          showModal={true}
          toggleModal={setNoShowSession}
          id={noShowSession}
        />
      )}
      <div className={`sessions ${!singleSessionsOnly && 'mt-3'}`}>
        <>
          {canAddSessions && (sessionsOnly || singleSessionsOnly) && (
            <JobPage
              setSchedulePollingInterval={setSchedulePollingInterval}
              setSessionListPollingInterval={setPollInterval}
              jobDetailSessions={true}
              jobId={jobId}
              singleSessionsOnly={singleSessionsOnly}
              sessionId={sessionId}
              toggleModal={toggleModal}
            />
          )}
          {!singleSessionsOnly && (
            <>
              {!initialQueryRun &&
              (sessionsQueryLoading ||
                !sessionsQueryData ||
                !awsS3?.client ||
                !awsS3?.bucket) ? (
                <Loading message="Loading Sessions..." />
              ) : (
                <>
                  {!contact && (
                    <Row>
                      <Col>
                        <span
                          onClick={() => {
                            setFilterCancelledSessions(!filterCancelledSessions)
                          }}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginLeft: '-20px',
                            padding: '10px 20px',
                          }}
                        >
                          <Form.Check
                            name="filterCancelledSessions"
                            type="switch"
                            label="Cancelled Sessions"
                            checked={filterCancelledSessions}
                            style={{
                              fontSize: '1rem',
                              color: '#333',
                            }}
                          />
                        </span>
                      </Col>
                    </Row>
                  )}
                  {sessions.length > 0 || loadingSessions ? (
                    <>
                      <Row className="mt-2">
                        <Col md={12}>
                          <SortableInfiniteTable
                            tableData={sessions}
                            searchPlaceholder="Search Sessions"
                            tableColumns={tableColumns}
                            tableHeight={550}
                            loading={loadingSessions}
                            hasMoreTableData={false}
                            onTdClicks={{
                              subjectGroup: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              id: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              organization: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              subject: (cell) => {
                                if (cell.row.original.node.subject) {
                                  if (orgContact) {
                                    history.push(
                                      `/subject/${cell.row.original.node.subject.id}/contact`
                                    )
                                  } else {
                                    history.push(
                                      `/subject/${cell.row.original.node.subject.id}`
                                    )
                                  }
                                } else {
                                  setDetailSessionId(cell.row.original.id)
                                }
                              },
                              subjectEmail: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              subjectPhone: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              status: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              time: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              sessionPackage: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                              billSubject: (cell) => {
                                setDetailSessionId(cell.row.original.id)
                              },
                            }}
                            rowPointer
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '20px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        color: '#555',
                      }}
                    >
                      {filterCancelledSessions
                        ? 'No Cancelled Sessions'
                        : 'No Booked Sessions'}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
        {orgContact && detailSessionId && (
          <SessionDetailModal
            showModal={detailSessionId}
            toggleModal={setDetailSessionId}
            sessionId={detailSessionId}
          />
        )}
        {!orgContact && detailSessionId && (
          <AdminSessionDetailModal
            toggleModal={setDetailSessionId}
            sessionId={detailSessionId}
            showModal={detailSessionId}
            setRemoveCancelledSession={setRemoveCancelledSession}
          />
        )}

        <FotomerchantGalleryPasswordModal
          showModal={fotomerchantGalleryPassword}
          toggleModal={setFotomerchantGalleryPassword}
          fotomerchantPassword={fotomerchantGalleryPassword}
        />

        <SessionDetailFormModal
          jobId={jobId}
          sessionId={selectedSessionId}
          showModal={showSessionDetailModal}
          toggleModal={() => {
            setShowSessionDetailModal(false)
            setSelectedSessionId(null)
          }}
        />
      </div>
    </>
  )
}
export default JobDetailSessionsTable
