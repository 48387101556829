import React, { useEffect, useState } from 'react'
import FileViewer from 'react-file-viewer'
import config from '../../config'
import Loading from '../common/Loading'
import Papa from 'papaparse'

const FILE_VIEWER_EXTENSIONS = [
  'pdf',
  'docx',
  'xlsx',
  'mp4',
  'webm',
  'mov',
  'avi',
  'mp3',
]

const IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg', 'gif', 'bmp']

const FilePreview = ({ file }) => {
  const [fileUrl, setFileUrl] = useState(null)
  const [fileContent, setFileContent] = useState(null)
  const [csvData, setCsvData] = useState(null)
  const [blobUrl, setBlobUrl] = useState(null)

  useEffect(() => {
    if (file && !blobUrl) {
      let url
      if (config.ENVIRONMENT === 'development') {
        url = `http://localhost:8000/core/file/${file.id}/`
      } else if (config.ENVIRONMENT === 'prod') {
        url = `https://api.airstudio.io/core/file/${file.id}/`
      } else if (config.ENVIRONMENT === 'srp') {
        url = `https://srp-api.airstudio.io/core/file/${file.id}/`
      }
      const exension = file.fileExtension.toLowerCase()
      if (exension === 'txt') {
        fetch(url)
          .then((response) => response.text())
          .then((text) => setFileContent(text))
          .catch((error) => console.error('Error fetching text file:', error))
      } else if (IMAGE_EXTENSIONS.includes(exension)) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob)
            setBlobUrl(blobUrl)
          })
          .catch((error) => console.error('Error fetching image file:', error))
      } else if (exension === 'csv') {
        fetch(url)
          .then((response) => response.text())
          .then((csvText) => {
            Papa.parse(csvText, {
              header: true,
              complete: (result) => setCsvData(result.data),
              error: (error) => console.error('Error parsing CSV:', error),
            })
          })
          .catch((error) => console.error('Error fetching CSV file:', error))
      } else {
        setFileUrl(url)
      }
    }
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl)
      }
    }
  }, [file, blobUrl])

  if (!fileUrl && !fileContent && !blobUrl && !csvData) return <Loading />

  const renderCsvTable = () => {
    if (!csvData || csvData.length === 0) return null

    const headers = Object.keys(csvData[0])

    return (
      <div
        style={{
          overflowX: 'auto',
          maxWidth: '100%',
          margin: '20px 0',
        }}
      >
        <table
          style={{
            borderCollapse: 'collapse',
            width: 'max-content',
            minWidth: '100%',
          }}
        >
          <thead>
            <tr>
              {headers.map((header, idx) => (
                <th
                  key={idx}
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    backgroundColor: '#f2f2f2',
                    textAlign: 'left',
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, idx) => (
                  <td
                    key={idx}
                    style={{
                      border: '1px solid #ddd',
                      padding: '8px',
                      textAlign: 'left',
                    }}
                  >
                    {row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderFileViewer = () => {
    const exension = file.fileExtension.toLowerCase()
    if (blobUrl && IMAGE_EXTENSIONS.includes(exension)) {
      return (
        <div
          style={{
            textAlign: 'center',
            margin: '20px 0',
          }}
        >
          <img
            src={blobUrl}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              width: 'auto',
              height: 'auto',
            }}
          />
        </div>
      )
    } else if (FILE_VIEWER_EXTENSIONS.includes(exension)) {
      return <FileViewer fileType={file.fileExtension} filePath={fileUrl} />
    } else if (exension === 'txt') {
      return (
        <div
          style={{
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            margin: '20px auto',
            maxWidth: '800px',
            overflow: 'auto',
          }}
        >
          <pre>{fileContent}</pre>
        </div>
      )
    } else if (exension === 'csv') {
      return renderCsvTable()
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            color: '#555',
          }}
        >
          Preview Not Supported For {exension} Files
        </div>
      )
    }
  }

  return <>{renderFileViewer()}</>
}

export default FilePreview
