import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { clearLoginVars, settingsVar } from '../../../libs/apollo'
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar'
import {
  Unlock,
  Lock,
  Camera,
  PersonBoundingBox,
  BoxArrowInLeft,
} from 'react-bootstrap-icons'
import './Navigation.css'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { useIntercom } from 'react-use-intercom'

const SubjectNavigation = (props) => {
  const { setNavigationOpen } = props
  let isCollapsed = false
  let locked = true
  let navState = localStorage.getItem('navState')
  if (navState) {
    navState = JSON.parse(navState)
    isCollapsed = navState.collapsed
    locked = navState.locked
  }
  const settings = useReactiveVar(settingsVar)
  const [navLocked, setNavLocked] = useState(locked)
  const { shutdown } = useIntercom()
  const location = useLocation()
  const { collapsed, collapseSidebar } = useProSidebar()
  const history = useHistory()

  useEffect(() => {
    if (!collapsed) {
      setNavigationOpen(true)
    } else {
      setNavigationOpen(false)
    }
  }, [setNavigationOpen, collapsed])

  const handleLogout = () => {
    clearLoginVars()
    localStorage.clear()
    if (settings.intercomAppId) {
      shutdown()
    }
    history.push('/')
  }

  const toggleSidebar = (open) => {
    if (!navLocked) {
      localStorage.setItem(
        'navState',
        JSON.stringify({ collapsed: open, locked: navLocked })
      )
      collapseSidebar(open)
    }
  }

  useEffect(() => {
    setNavigationOpen(!collapsed)
  }, [setNavigationOpen, collapsed])

  if (!settings) return <></>
  return (
    <>
      <div
        style={{ backgroundColor: settings.colorScheme, flex: 1 }}
        className="sidebar-container"
      >
        <Sidebar collapsed={isCollapsed} width="120px">
          <Menu>
            <MenuItem
              onMouseEnter={() => {
                toggleSidebar(false)
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
              onClick={() => history.push('/subject/sessions')}
              className={
                location.pathname === '/subject/sessions'
                  ? 'menu-item-active'
                  : ''
              }
              active={location.pathname === '/subject/sessions'}
            >
              {collapsed ? (
                <Camera />
              ) : (
                <>
                  <Camera className="mr-2" />
                  <span style={{ fontSize: '12.5px' }}>Sessions</span>
                </>
              )}
            </MenuItem>
            <MenuItem
              onMouseEnter={() => {
                toggleSidebar(false)
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
              onClick={() => history.push('/subject/settings')}
              className={
                location.pathname === '/subject/settings'
                  ? 'menu-item-active'
                  : ''
              }
              active={location.pathname === '/subject/settings'}
            >
              {collapsed ? (
                <PersonBoundingBox />
              ) : (
                <>
                  <PersonBoundingBox className="mr-2" />
                  <span style={{ fontSize: '12.5px' }}>Profile</span>
                </>
              )}
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              className="title-space"
              onMouseEnter={() => {
                toggleSidebar(false)
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {collapsed ? (
                <BoxArrowInLeft />
              ) : (
                <>
                  <BoxArrowInLeft className="mr-2" />
                  <span style={{ fontSize: '12.5px' }}>Logout</span>
                </>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  'navState',
                  JSON.stringify({ collapsed, locked: !navLocked })
                )
                setNavLocked(!navLocked)
              }}
              onMouseEnter={() => {
                if (!collapsed) {
                  toggleSidebar(false)
                }
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {navLocked && <Lock style={{ fontSize: '14px' }} />}
              {!navLocked && <Unlock style={{ fontSize: '14px' }} />}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  )
}

export default SubjectNavigation
