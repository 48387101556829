import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteTaskStatusModal = (props) => {
  const { taskStatus, showModal, toggleModal } = props

  const [deleteTaskStatus] = useMutation(
    gql`
      mutation DeleteTaskStatusMutations(
        $deleteTaskStatusInput: DeleteTaskStatusInput!
      ) {
        deleteTaskStatus(input: $deleteTaskStatusInput) {
          deleted
        }
      }
    `,
    {
      refetchQueries: ['TaskStatuses'],
      onCompleted: () => {
        toast.success('Status Deleted')
        toggleModal()
      },
    }
  )

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>
        <Modal.Title closeButton>Delete Task Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              Are you sure you want to delete {taskStatus.name} task status?
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button
              variant="link"
              size="sm"
              block
              style={{ color: 'red !important' }}
              onClick={() => {
                deleteTaskStatus({
                  variables: {
                    deleteTaskStatusInput: {
                      taskStatusIds: [taskStatus.id],
                    },
                  },
                })
              }}
            >
              <Trash className="mr-2" />
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteTaskStatusModal
